import axios from "axios";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../services/notificationService";
import useDeployContract from "./useDeployContract";
import { PROPOSAL_CONTRACT_ABI } from "../Constants/DAOContractConstructs";
import usePostTokenData from "./usePostTokenData";
import usePostDAOData from "./usePostDAOData";
import { getFilteredEntities, insertEntity, triggerTedaasWorkflow } from "../../../services/stf";
import { TEDAAS_SCHEMAS, TEDAAS_WORKFLOWS } from "../Constants/Constants";
import { updateLoader } from "../../../redux/actions/loaderAction";
import usePostProposalData from "./usePostProposalData";
import { useParams } from "react-router-dom";

export const useProposalAction = () => {
    const signer = useSelector((state) => state.auth.signer);
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const daoData = useSelector((state) => state.dc);
    const daoDetails = useSelector((state) => state.dd);
    const proposalData = useSelector((state) => state.pc);
    const proposalDetails = useSelector((state) => state.pd);
    const { getConstructorParams } = useDeployContract();
    const { postNewTokenTxn, postTokenHolders, tokenHoldersUpdate } = usePostTokenData();
    const { postDAOData, postDAOMembersData, updateDAO } = usePostDAOData();
    const { postVotingData, postProposalExecuteData } = usePostProposalData();
    const dispatch = useDispatch();
    const { proposalAddress } = useParams();

    const createContractInstance = () => {
        try {
            const contractAbi = PROPOSAL_CONTRACT_ABI;
            const contract = new ethers.Contract(proposalAddress, contractAbi, signer);
            return { contract, contractAbi };
        } catch (e) {
            console.log("Error in Create Contract Instance", e);
            throw Error("\nError in Creating Contract Instance > " + e);
        }
    };
    const voteOnProposal = async (vote) => {
        try {
            dispatch(updateLoader(true));
            const { contract, contractAbi } = createContractInstance();
            const voting = await contract.vote(vote == "Yes" ? 1 : vote == "No" ? 2 : 0);
            const tx = voting.wait();
            const res = await postVotingData(proposalAddress, signerAddress, vote);
            const decimalValue = parseInt(voting.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                daoDetails.tokenAddress,
                contractAbi,
                voting.hash,
                GasgweiValue.toFixed(2),
                0,
                signerAddress,
                proposalDetails.daoAddress,
                "Vote",
                vote,
                proposalAddress,
            );
            dispatch(updateLoader(false));
            showSuccessToast("Voting Successfull", "You voted " + vote);
            return true;
        } catch (e) {
            dispatch(updateLoader(false));
            showErrorToast("Error in voting");
            return false;
        }
    };
    const executeProposal = async () => {
        try {
            dispatch(updateLoader(true));
            const { contract, contractAbi } = createContractInstance();
            console.log("Execution Actions");
            const voting = await contract.executeProposal();
            console.log("Transaction");
            const tx = await voting.wait();
            console.log("Execution Completed");
            const decimalValue = parseInt(voting.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                daoDetails.tokenAddress,
                contractAbi,
                voting.hash,
                GasgweiValue.toFixed(2),
                0,
                signerAddress,
                proposalDetails.daoAddress,
                "Proposal",
                "Proposal Executed",
                proposalAddress,
            );
            if (proposalDetails.actions.mint.length > 0) {
                console.log("Minting Tokens");
                const mint = [];
                for (let i of proposalDetails.actions.mint) {
                    mint.push({
                        memberAddress: i.address || i.memberAddress,
                        deposit: i.tokens,
                    });
                }
                await tokenHoldersUpdate(mint, daoDetails.tokenAddress);
                await postDAOMembersData(mint, daoDetails.daoAddress);
            }
            if (proposalDetails.actions.withdraw.length > 0) {
                console.log("Withdrawing Assets");
            }
            if (Object.keys(proposalDetails.actions.settings).length > 0) {
                console.log("Updating DAO Settings");
                await updateDAO(proposalDetails.actions.settings);
            }
            if (proposalDetails.actions.addWallets.length > 0) {
                console.log("Adding Wallets");
                const addWallets = [];
                for (let i of proposalDetails.actions.addWallets) {
                    addWallets.push({
                        memberAddress: i,
                    });
                }
                await postDAOMembersData(addWallets, daoDetails.daoAddress);
            }
            if (proposalDetails.actions.removeWallets.length > 0) {
                console.log("Removing Wallets");
                const removeWallets = [];
                for (let i of proposalDetails.actions.removeWallets) {
                    removeWallets.push({
                        memberAddress: i,
                    });
                }
                await postDAOMembersData(removeWallets, daoDetails.daoAddress, true);
            }
            await postProposalExecuteData(proposalAddress, daoDetails.tokenAddress, true, true);
            dispatch(updateLoader(false));
            showSuccessToast("Executed Successfully");
            return true;
        } catch (e) {
            console.log(e);
            dispatch(updateLoader(false));
            showErrorToast("Error in Executing");
            return false;
        }
    };
    const countYesVotes = async () => {
        try {
            const { contract, contractAbi } = createContractInstance();
            const voting = await contract.yesVotes();
            return voting;
        } catch (e) {
            showErrorToast("Error in Executing");
            return false;
        }
    };
    const countNoVotes = async () => {
        try {
            const { contract, contractAbi } = createContractInstance();
            const voting = await contract.noVotes();
            return voting;
        } catch (e) {
            showErrorToast("Error in Executing");
            return false;
        }
    };
    return { voteOnProposal, executeProposal, countYesVotes, countNoVotes };
};
