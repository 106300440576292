import React, { useState } from "react";
import css from "./ProposalMetaData.module.scss";

import { Input, Select, Row, Col, Button } from "antd";
import "antd/dist/reset.css";  

import DAOStudioProgress from "../../../Components/UI/DAOStudioProgress/DAOStudioProgress";

import AuhtorIcon from "../../../TeDaaS-Assets/Porposal/AuthorIcon.svg";
import generateAIImg from "../../../TeDaaS-Assets/DaoCreate/generateAI.svg";
import MenuDots from "../../../TeDaaS-Assets/Porposal/MenuDots.svg";

import { useDispatch, useSelector } from "react-redux";
import CreateProposalBodyHeader from "../../../Components/UI/CreateProposalBodyHeader/CreateProposalBodyHeader";
import { Link, useNavigate } from "react-router-dom";
import { proposalCreationConfigureVal } from "../../../../../redux/actions/proposalCreationAction";

const ProposalMetaData = () => {
    const proposalCreatorAddress = useSelector((state) => state.auth.userAddress);
    const fetchedData = useSelector((state) => state.pc);
    const data = fetchedData.proposalMetaData;
    const dispatch = useDispatch();
    const navigateTo = useNavigate();

    const inputConstants = {
        ProposalTitle: "proposalTitle",
        ProposalDescription: "proposalDescription",
        ProposalBody: "proposalBody",
        ProposalResources: "proposalResources",
    };

    const [proposalMetaData, setProposalMetaData] = useState({
        proposalAuthor: proposalCreatorAddress,
        proposalTitle: data.proposalTitle,
        proposalDescription: data.proposalDescription,
        proposalBody: data.proposalBody,
        proposalResources: data.proposalResources,
    });

    const [proposalBodyStyle, setProposalBodyStyle] = useState({
        fontSize: "20px",
        textAlign: "left",
        fontWeight: 400,
        fontColor: "#000000",
    });

    const handleInputTextChange = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case inputConstants.ProposalTitle:
                setProposalMetaData({
                    ...proposalMetaData,
                    proposalTitle: value,
                });
                break;

            case inputConstants.ProposalDescription:
                setProposalMetaData({
                    ...proposalMetaData,
                    proposalDescription: value,
                });
                break;

            case inputConstants.ProposalBody:
                setProposalMetaData({
                    ...proposalMetaData,
                    proposalBody: value,
                });
                break;

            case inputConstants.ProposalResources:
                setProposalMetaData({
                    ...proposalMetaData,
                    proposalResources: [
                        ...proposalMetaData.proposalResources,
                        {
                            resourceName: "",
                            resourceLink: "",
                        },
                    ],
                });
                break;
        }
    };

    const handleRemoveResource = (index) => {
        if (proposalMetaData.proposalResources.length > 1) {
            const newResources = proposalMetaData.proposalResources.filter((_, i) => i !== index);
            setProposalMetaData({
                ...proposalMetaData,
                proposalResources: newResources,
            });
        }
    };

    const handleNext = () => {
        dispatch(proposalCreationConfigureVal("proposalMetaData", proposalMetaData));
        navigateTo("/tedaasV3/createProposal/proposalVote");
    };

    return (
        <div className={css.mainContainer}>
            <DAOStudioProgress
                title="Create Proposal"
                description="Provide the information voters will need to make their decision here. "
                progress={30}
            />
            <div className={`${css.mainWrapper} ${css.mt_32}`}>
                <div className={css.divContainer}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Author</h4>
                    </div>
                    <div className={css.authorWrapper}>
                        <span>You</span>
                        <img src={AuhtorIcon} alt="logo" />
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Title</h4>
                    </div>
                    <div className={css.inputWrapper}>
                        <input
                            placeholder="Proposal title"
                            value={proposalMetaData.proposalTitle}
                            name={inputConstants.ProposalTitle}
                            onChange={handleInputTextChange}
                            className={css.inputText}
                        />
                        <span>Give your proposal a Title</span>
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Description</h4>
                        <div className={css.generateUsingAI}>
                            <img className={css.icon} src={generateAIImg} alt="Generate Using AI" />
                            <span>Generate using AI</span>
                        </div>
                    </div>
                    <div className={css.inputWrapper}>
                        <textarea
                            placeholder="Proposal Description"
                            value={proposalMetaData.proposalDescription}
                            name={inputConstants.ProposalDescription}
                            onChange={handleInputTextChange}
                            className={`${css.inputTextArea} ${css.min_h_64}`}
                        />
                        <span>Describe your Proposal in 2-3 Sentences. This will appear in the proposal overview</span>
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Body(Optional)</h4>
                        <div className={css.generateUsingAI}>
                            <img className={css.icon} src={generateAIImg} alt="Generate Using AI" />
                            <span>Generate using AI</span>
                        </div>
                    </div>
                    <div className={css.inputWrapper}>
                        <div className={css.bodyInputContainer}>
                            <CreateProposalBodyHeader style={proposalBodyStyle} setStyle={setProposalBodyStyle} />
                            <textarea
                                placeholder="Proposal body"
                                value={proposalMetaData.proposalBody}
                                name={inputConstants.ProposalBody}
                                onChange={handleInputTextChange}
                                className={`${css.inputTextArea} ${css.min_h_128}`}
                                style={{
                                    fontSize: proposalBodyStyle.fontSize,
                                    textAlign: proposalBodyStyle.textAlign,
                                    fontWeight: proposalBodyStyle.fontWeight,
                                    color: proposalBodyStyle.fontColor,
                                }}
                            />
                        </div>
                        <span>Write the body of your Proposal Here</span>
                    </div>
                </div>

                <div className={`${css.divContainer} ${css.mt_32}`}>
                    <div className={css.headingContainer}>
                        <h4 className={css.fieldHeader}>Resources(Optional)</h4>
                    </div>
                    <div className={css.inputWrapper}>
                        {proposalMetaData.proposalResources.map((key, i) => (
                            <div className={css.resourceContainer}>
                                <input placeholder="eg: website, Discord etc." />
                                <input placeholder="https://" />
                                <div className={css.imgContainer} onClick={() => handleRemoveResource(i)}>
                                    <img src={MenuDots} alt="Remove" />
                                </div>
                            </div>
                        ))}
                        <span>Share external resources here</span>
                        <button name={inputConstants.ProposalResources} onClick={handleInputTextChange}>
                            Add Resource
                        </button>
                    </div>
                </div>
            </div>

            <div className={css.separatorLine}></div>

            <div className={css.navButtons}>
                <Link to={`/tedaasV3/dao/${fetchedData.daoAddress}`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                {/* <Link to={`/tedaasV3/createProposal/proposalVote`}> */}
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
                {/* </Link> */}
            </div>
        </div>
    );
};

export default ProposalMetaData;
