import React, { useEffect, useState } from "react";
import DashboardHeader from "../../Components/Layout/DashboardHeader/DashboardHeader";
import css from "./TokenStudioDashboard.module.scss";
import NetworkInsightsCard from "../../Components/UI/NetworkInsightsCard/NetworkInsightsCard";
import profitLogo from "../../TeDaaS-Assets/TeDaaSDashboard/profitArrowHead.svg";
import lossLogo from "../../TeDaaS-Assets/TeDaaSDashboard/lossArrowHead.svg";
import DoughnutChartWithLegends from "../../Components/UI/DoughnutChartWithLegends/DoughnutChartWithLegends";
import gasPriceIcon from "../../TeDaaS-Assets/TeDaaSDashboard/gasPrice.svg";
import MultilineChartWithLegends from "../../Components/UI/MultilineChartWithLegends/MultilineChartWithLegends";
import Table from "../../Components/Layout/Table/Table";
import Search from "antd/es/input/Search";
import { FilterOutlined, PlusOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateShowRightSideBar } from "../../../../redux/actions/rightSideBarAction";
import MultilineChart from "../../Components/Charts/MultilineChart/MultilineChart";
import { data } from "../../Constants/tokenStudioData.js";
import CreateNewTokenModal from "../../Components/Modals/CreateNewTokenModal.jsx";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions.js";
import { tokenCreationOpenModel } from "../../../../redux/actions/tokenCreationOptionsAction.js";
import {
    getEntities,
    getFilteredEntities,
    getGroupAdhocata,
    getTokenHoldersFromJSONSERVER,
    getTokensFromJSONSERVER,
} from "../../../../services/stf.js";
// import AegisIcon from "../../TeDaaS-Assets/AegisIcon.svg";
import AegisIcon from "../../TeDaaS-Assets/AegisIcon.svg";
import dummyLogo from "../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
// import { updateUserAllToken, updateUserTokenDetails } from "../../../../redux/actions/tokenDataAction.js";
import { useERC20TokenAction } from "../../Hooks/useERC20TokenAction.jsx";
import { TEDAAS_SCHEMAS } from "../../Constants/Constants.js";
import { updateLoader } from "../../../../redux/actions/loaderAction.js";
import TokenomicsSimulationSearchParameter from "../../Components/UI/TokenomicsSimulationSearchParameter/TokenomicsSimulationSearchParameter.jsx";
import { tokenCreation } from "../../../../redux/actions/tokenCreationAction.js";

function TokenStudioDashboard() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const userAddress = useSelector((state) => state.auth.userAddress);
    const { getAddressBalance } = useERC20TokenAction();
    const [search, setSearch] = useState("");
    const [userTokenDetail, setUserTokenDetail] = useState([]);
    const fetchTokenDetails = async () => {
        try {
            dispatch(updateLoader(true));
            const tokenHoldersRes = await getFilteredEntities(
                { userAddress: userAddress },
                TEDAAS_SCHEMAS.tokenHoldersId,
                TEDAAS_SCHEMAS.accessToken,
            );
            const tokenHoldersData = tokenHoldersRes.entities;
            const tokenData = [];
            const tokenHolders = {};
            console.log("Token Holders Data", tokenHoldersData);
            const definition =
                "SELECT * FROM t_" +
                TEDAAS_SCHEMAS.tokenId +
                "_t AS t1 JOIN t_" +
                TEDAAS_SCHEMAS.tokenHoldersId +
                "_t AS t2 ON t1.`entity.tokenAddress` = t2.`entity.tokenAddress` WHERE t2.`entity.userAddress` = '" +
                userAddress +
                "'";
            const res = await getGroupAdhocata(definition, TEDAAS_SCHEMAS.accessToken);
            for (let i of res.model.data) {
                tokenData.push(i.entity);
                if (tokenHolders[i.entity.userAddress] === undefined) {
                    tokenHolders[i.entity.userAddress] = {};
                }
                tokenHolders[i.entity.userAddress][i.entity.tokenAddress] = i.entity.tokens;
            }
            console.log(tokenData);
            // dispatch(updateUserTokenDetails(tokenHolders));
            // dispatch(updateUserAllToken(tokenData));
            const tokenUpdatedData = tokenData.map((d, i) => {
                var tokens = Math.round(Math.random() * 100);
                if (tokenHolders[userAddress] && tokenHolders[userAddress][d.tokenAddress]) {
                    tokens = tokenHolders[userAddress][d.tokenAddress];
                }
                console.log(d);
                return {
                    ...d,
                    tokenSymbol: {
                        name: d.tokenSymbol,
                        image: d.icon || dummyLogo,
                    },
                    product: {
                        name: "Aegis",
                        image: AegisIcon,
                    },
                    createdOn: d.createdAt,
                    price: {
                        value: `$${d.price || 0}`,
                        percent: `+%${(Math.random() * 15).toFixed(2)}`,
                        isGain: true,
                    },
                    balance: `${tokens}`,
                };
            });
            setUserTokenDetail(tokenUpdatedData);
            dispatch(updateLoader(false));
        } catch (e) {
            console.log("Error in Fetching Token Details", e);
            dispatch(updateLoader(false));
        }
    };
    useEffect(() => {
        if (localStorage.getItem("tokenCreationData")) {
            var tokenCreationData = JSON.parse(localStorage.getItem("tokenCreationData"));
            dispatch(tokenCreation({ ...tokenCreationData, isNewToken: true }));
        }
        fetchTokenDetails();
        dispatch(updateShowRightSideBar(false));
        dispatch(
            updateTopBarStyle(
                <div className={css.headerTitle}>
                    <span className={css.greyText}>MarketPlace /</span>
                    <span className={css.greyText}>&nbsp;TEDaaS /</span>
                    <span className={css.blackText}>&nbsp;Token Studio</span>
                </div>,
                "#f5f6f7",
                "100%",
            ),
        );
    }, [userAddress]);
    return (
        <div className={css.container}>
            <div className={css.tokenStudioBody}>
                <span className={css.title}>Token Studio</span>
                <div className={css.cards}>
                    {Array.isArray(data.tokenInsights) &&
                        data.tokenInsights.map((c, i) => {
                            return <NetworkInsightsCard key={i} value={c.value} name={c.name} percent={c.percent} isProfit={c.isProfit} />;
                        })}
                </div>
                <div className={css.charts}>
                    <div className={`${css.chartContainer} ${css.totalBalance}`}>
                        <span className={css.totalBalanceName}>Total Balance</span>
                        <div className={css.totalBalanceMid}>
                            <div className={css.totalBalanceMidTop}>
                                <span className={css.totalBalanceMidTopLeft}>$5,987.34</span>
                                <div className={`${css.totalBalanceMidTopRight} ${true ? css.gain : css.loss}`}>
                                    <img
                                        className={css.icon}
                                        src={true ? profitLogo : lossLogo}
                                        style={{ rotate: true ? "0deg" : "180deg" }}
                                        alt="Total Balance"
                                    />
                                    <span className={css.totalBalanceMidTopRightText}>+7.9%</span>
                                </div>
                            </div>
                            <span className={css.totalBalanceMidBottom}>Detailed volume trends & market liquidity over time.</span>
                        </div>
                        <div className={css.chart}>
                            <MultilineChart
                                series={data.tokenBalance.series}
                                xLegends={data.tokenBalance.xLegends}
                                postfix="K"
                                xAxisLine={false}
                                xAxisTick={false}
                            />
                        </div>
                    </div>
                    <div className={css.tokenAllocationAndGasPrice}>
                        <div className={`${css.chartContainer} ${css.tokenAllocation}`}>
                            <span className={css.tokenAllocationName}>Token Allocation</span>
                            <div className={css.chart}>
                                <DoughnutChartWithLegends data={data.tokenAllocation} />
                            </div>
                        </div>
                        <div className={`${css.chartContainer} ${css.gasPrice}`}>
                            <span className={css.gasPriceName}>Gas Price</span>
                            <div className={css.gasPriceCards}>
                                <div className={`${css.slow} ${css.gasPriceCard}`}>
                                    <img className={css.icon} src={gasPriceIcon} alt="Slow" />
                                    <div className={css.details}>
                                        <span className={css.gasPriceName}>Slow</span>
                                        <span className={css.gasPriceName}>15 mins</span>
                                        <span className={css.gasPriceValue}>0.038 ETH</span>
                                        <span className={css.gasPriceValue}>$15.45308</span>
                                    </div>
                                </div>
                                <div className={`${css.normal} ${css.gasPriceCard}`}>
                                    <img className={css.icon} src={gasPriceIcon} alt="Normal" />
                                    <div className={css.details}>
                                        <span className={css.gasPriceName}>Normal</span>
                                        <span className={css.gasPriceName}>3 mins</span>
                                        <span className={css.gasPriceValue}>0.05 ETH</span>
                                        <span className={css.gasPriceValue}>$20.333</span>
                                    </div>
                                </div>
                                <div className={`${css.fast} ${css.gasPriceCard}`}>
                                    <img className={css.icon} src={gasPriceIcon} alt="Fast" />
                                    <div className={css.details}>
                                        <span className={css.gasPriceName}>Fast</span>
                                        <span className={css.gasPriceName}>24 Sec</span>
                                        <span className={css.gasPriceValue}>0.05475 ETH</span>
                                        <span className={css.gasPriceValue}>$22.26463</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={`${css.chartContainer} ${css.tokenPerformance}`}>
                        <span className={css.tokenPerformanceName}>Token Performance</span>
                        <div className={css.chart}>
                            <MultilineChartWithLegends series={data.tokenPerformance.series} yAxisSplitLine={false} />
                        </div>
                    </div>
                </div>
                <div className={css.myTokens}>
                    <span className={css.title}>My Tokens</span>
                    <div className={css.myTokensRight}>
                        <TokenomicsSimulationSearchParameter placeholder="Search..." search={search} setSearch={setSearch} />
                        <Button className={css.filter}>
                            <FilterOutlined />
                            Filter
                        </Button>
                        <Button
                            className={css.createToken}
                            color="#5E43D8"
                            type="primary"
                            onClick={() => {
                                dispatch(tokenCreationOpenModel("newToken"));
                            }}
                        >
                            <PlusOutlined /> Create Token
                        </Button>
                    </div>
                </div>
                <div className={css.table}>
                    <Table path="manageToken" columnDefination={data.tokens.columns} tableData={userTokenDetail} search={search} />
                </div>
            </div>
        </div>
    );
}

export default TokenStudioDashboard;
