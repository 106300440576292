import React, { useEffect, useState } from "react";
import css from "./DAODetails.module.scss";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { updateTopBarStyle } from "../../../../redux/actions/topbarActions";
import backImg from "../../TeDaaS-Assets/DaoCreate/back.svg";
import blueDownImg from "../../TeDaaS-Assets/DaoCreate/blueDown.svg";
import { Anchor } from "antd";
import DAODetailsDashboard from "../../Components/Layout/DAODetails/DAODetailsDashboard/DAODetailsDashboard";
import DAODetailsProposals from "../../Components/Layout/DAODetails/DAODetailsProposals/DAODetailsProposals";
import DAODetailsMembers from "../../Components/Layout/DAODetails/DAODetailsMembers/DAODetailsMembers";
import { deleteEntity, getFilteredEntities } from "../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../Constants/Constants";
import { updateLoader } from "../../../../redux/actions/loaderAction";
import { showErrorToast } from "../../../../services/notificationService";
import DAODetailsSettings from "../../Components/Layout/DAODetails/DAODetailsSettings/DAODetailsSettings";
import { daoDataConfigureVal } from "../../../../redux/actions/daoDataAction";
import { tokenDataConfigureVal } from "../../../../redux/actions/tokenDataAction";
import DAODetailsFinance from "../../Components/Layout/DAODetails/DAODetailsFinance/DAODetailsFinance";
import DAODetailsFinanceTokens from "../../Components/Layout/DAODetails/DAODetailsFinance/DAODetailsFinanceTokens/DAODetailsFinanceTokens";
import DAODetailsFinanceTransfers from "../../Components/Layout/DAODetails/DAODetailsFinance/DAODetailsFinanceTransfers/DAODetailsFinanceTransfers";
import { useDAOAction } from "../../Hooks/useDAOAction";

function DAODetails() {
    // const fetchedData = useSelector((state) => state.dd);
    const { daoAddress } = useParams();
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const [page, setPage] = useState("dashboard");
    const [dao, setDao] = useState({});
    const [treasury, setTreasury] = useState([]);
    const [members, setMembers] = useState([]);
    const [proposals, setProposals] = useState([]);
    const token = useSelector((state) => state.td);
    const {checkDAOMember, checkDAOTreasury} = useDAOAction();
    const fetchDAODetails = async () => {
        dispatch(updateLoader(true));
        try {
            const daoRes = await getFilteredEntities({ daoAddress: daoAddress }, TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
            if (daoRes.entities.length > 0) {
                const tempTreasury = await checkDAOTreasury(daoAddress);
                setTreasury(tempTreasury);
                setDao({ logo: "", ...daoRes.entities[0] });
                dispatch(daoDataConfigureVal({ logo: "", ...daoRes.entities[0] }));
                const memberRes = await getFilteredEntities({ daoAddress: daoAddress }, TEDAAS_SCHEMAS.daoMemFollId, TEDAAS_SCHEMAS.accessToken);
                const memberJson = {};
                if (memberRes.entities.length > 0) {
                    for (let i of memberRes.entities) {
                        memberJson[i.userAddress] = i;
                    }
                }
                const verified = await checkDAOMember(daoAddress, memberRes.entities);
                const proposalRes = await getFilteredEntities({ daoAddress: daoAddress }, TEDAAS_SCHEMAS.proposalId, TEDAAS_SCHEMAS.accessToken);
                if (proposalRes.entities.length > 0) {
                    setProposals(proposalRes.entities);
                }
                const tokenHoldersRes = await getFilteredEntities(
                    { tokenAddress: daoRes.entities[0].tokenAddress },
                    TEDAAS_SCHEMAS.tokenHoldersId,
                    TEDAAS_SCHEMAS.accessToken,
                );
                let totalTokens = 0;
                if (tokenHoldersRes.entities.length > 0) {
                    for (let i of tokenHoldersRes.entities) {
                        if (memberJson[i.userAddress]) {
                            memberJson[i.userAddress].tokens = parseFloat(i.tokens);
                            totalTokens += parseFloat(i.tokens);
                        }
                    }
                    // setToken(tokenHoldersRes.entities);
                }
                const tokenRes = await getFilteredEntities(
                    { tokenAddress: daoRes.entities[0].tokenAddress },
                    TEDAAS_SCHEMAS.tokenId,
                    TEDAAS_SCHEMAS.accessToken,
                );
                if (tokenRes.entities.length > 0) {
                    dispatch(tokenDataConfigureVal({ ...tokenRes.entities[0], totalTokens: totalTokens }));
                }
                setMembers(Object.values(memberJson));
                dispatch(
                    updateTopBarStyle(
                        <div className={css.headerTitle}>
                            <div
                                className={css.title}
                                onClick={() => {
                                    navigateTo("/tedaasV3/daoDashboard");
                                }}
                            >
                                <img className={css.icon} src={backImg} alt="Back" />
                                <span className={css.text}>{daoRes.entities[0].daoName}</span>
                            </div>
                            <div className={css.blueText}>
                                <span>{daoRes.entities[0].ensSubdomain}</span>
                                <img className={css.downIcon} src={blueDownImg} alt="Dao ETH" />
                            </div>
                        </div>,
                        "#ffffff",
                        "100%",
                    ),
                );
            } else {
                navigateTo("/tedaasV3/daoDashboard");
            }
        } catch (e) {
            console.log(e);
            showErrorToast("Unable to fetch data");
            navigateTo("/tedaasV3/daoDashboard");
        }
        dispatch(updateLoader(false));
    };
    useEffect(() => {
        fetchDAODetails();
    }, [daoAddress]);
    return (
        <div className={css.container}>
            <div className={css.anchorContainer}>
                <span
                    className={page === "dashboard" ? css.activeAnchor : css.anchor}
                    onClick={() => {
                        if (page !== "dashboard") {
                            setPage("dashboard");
                        }
                    }}
                >
                    Dashboard
                </span>
                <span
                    className={page === "governance" ? css.activeAnchor : css.anchor}
                    onClick={() => {
                        if (page !== "governance") {
                            setPage("governance");
                        }
                    }}
                >
                    Governance
                </span>
                <span
                    className={page === "finance" ? css.activeAnchor : css.anchor}
                    onClick={() => {
                        if (page !== "finance") {
                            setPage("finance");
                        }
                    }}
                >
                    Finance
                </span>
                <span
                    className={page === "members" ? css.activeAnchor : css.anchor}
                    onClick={() => {
                        if (page !== "members") {
                            setPage("members");
                        }
                    }}
                >
                    Members
                </span>
                <span
                    className={page === "settings" ? css.activeAnchor : css.anchor}
                    onClick={() => {
                        if (page !== "settings") {
                            setPage("settings");
                        }
                    }}
                >
                    Settings
                </span>
            </div>
            <div className={css.body}>
                {page === "dashboard" && <DAODetailsDashboard dao={dao} members={members} proposals={proposals} token={token} />}
                {page === "governance" && <DAODetailsProposals dao={dao} members={members} proposals={proposals} token={token} treasury={treasury} />}
                {page === "finance" && <DAODetailsFinance dao={dao} members={members} proposals={proposals} token={token} setPage={setPage} treasury={treasury} />}
                {page === "tokens" && <DAODetailsFinanceTokens dao={dao} members={members} proposals={proposals} token={token} setPage={setPage} treasury={treasury} />}
                {page === "transfers" && (
                    <DAODetailsFinanceTransfers dao={dao} members={members} proposals={proposals} token={token} setPage={setPage} treasury={treasury} />
                )}
                {page === "members" && <DAODetailsMembers dao={dao} members={members} proposals={proposals} token={token} />}
                {page === "settings" && <DAODetailsSettings dao={dao} members={members} proposals={proposals} token={token} />}
            </div>
        </div>
    );
}

export default DAODetails;
