import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    SMART_CONTRACT_COMPILER_ENDPOINT,
    SMART_CONTRACT_COMPILER_ENDPOINT_LOCAL,
    SMART_CONTRACT_COMPILER_ENDPOINT_NGROK,
    switchCaseConstants,
    TEDAAS_SCHEMAS,
} from "../Constants/Constants";
import { ERC20Code } from "../SmartContractCode/ERC20.js";
import { updateLoader } from "../../../redux/actions/loaderAction";
import axios from "axios";
import { ethers } from "ethers";
import usePostTokenData from "./usePostTokenData";
import utSourceCodeTxtFile from "../Constants/UT_SOURCE_CODE.txt";

import { showErrorToast, showWarningToast } from "../../../services/notificationService.jsx";
import { convertJsonToTimeStamp, convertToBasisPoints } from "../Constants/reusableFunctions.js";
import { getFilteredEntities, verifyContractViaEtherscan } from "../../../services/stf.js";
import { GovernanceTokenCode } from "../SmartContractCode/GovernanceToken.js";
import { DAOCode } from "../SmartContractCode/DAO.js";
import { tokenCreationReset } from "../../../redux/actions/tokenCreationAction.js";
import { tokenCreationNodeEdgeReset } from "../../../redux/actions/tokenCreationNodesAndEdgesAction.js";
import securityToken from "../Constants/SecurityTokenContractAbis.json";
import { UT_CONTRACT_VERIFICATION } from "../Constants/DAOContractConstructs.js";

const useDeployContract = () => {
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.tc);
    const daoData = useSelector((state) => state.dc);
    const daoDetails = useSelector((state) => state.dd);
    const proposalData = useSelector((state) => state.pc);
    const premintValue = fetchedData.initialSupply;
    const tokenSymbol = fetchedData.symbol;
    const tokenName = fetchedData.tokenName;
    const owner = fetchedData.owner;
    const mintData = fetchedData.mint;
    const burnData = fetchedData.burn;
    const pause = fetchedData.pause;
    const blacklist = fetchedData.blacklist;
    const changeOwner = fetchedData.changeOwner;
    const txTaxData = fetchedData.transactionTax;
    const buybackData = fetchedData.buyback;
    const rewardsDistributionData = fetchedData.rewards;
    const signingAddress = useSelector((state) => state.auth.signer);
    const userAddress = useSelector((state) => state.auth.userAddress);
    const { postTokenDetails } = usePostTokenData();
    // Function to encode the function call using ethers.js
    async function createTransferAction(data, daoAddress) {
        const actions = [];
        // Define the contract's ABI fragment for the `mintSupply` function
        const abiFragment = ["function addDAOMembers((address memberAddress, uint256 deposit)[] members) external"];
        const iface = new ethers.utils.Interface(abiFragment);

        const dataArray = Array.isArray(data) ? data : [data];
        for (let i of dataArray) {
            // const params = [i.address, parseInt(i.tokens)];
            // const encodedData = iface.encodeFunctionData("mintSupply", params);
            actions.push({
                memberAddress: i.address || i.memberAddress, // Example member address 1
                deposit: ethers.utils.parseEther(String(i.tokens || i.deposit)), // Example deposit 1 (1 Ether)
            });
        }
        const encodedData = iface.encodeFunctionData("addDAOMembers", [actions]);
        console.log(encodedData);
        return [[daoAddress, 0, encodedData]];
    }
    async function createAddWalletAction(data, daoAddress) {
        const actions = [];
        // Define the contract's ABI fragment for the `mintSupply` function
        const abiFragment = ["function addDAOMembers((address memberAddress, uint256 deposit)[] members) external"];
        const iface = new ethers.utils.Interface(abiFragment);

        const dataArray = Array.isArray(data) ? data : [data];
        for (let i of dataArray) {
            // const params = [i.address, parseInt(i.tokens)];
            // const encodedData = iface.encodeFunctionData("mintSupply", params);
            actions.push({
                memberAddress: i, // Example member address 1
                deposit: ethers.utils.parseEther(String(0)), // Example deposit 1 (1 Ether)
            });
        }
        const encodedData = iface.encodeFunctionData("addDAOMembers", [actions]);
        console.log(encodedData);
        return [[daoAddress, 0, encodedData]];
    }
    async function createUpdateDAOSettingsAction(daoSettings, daoAddress) {
        const abiFragment = ["function updateDaoSettings((string name, bytes data) _daoParams)"];
        // Create the interface for the function
        const iface = new ethers.utils.Interface(abiFragment);

        // Define the parameters to pass to the function
        const daoParams = {
            name: daoSettings?.daoName || daoDetails.daoName, // New DAO name
            data: ethers.utils.hexlify(
                ethers.utils.toUtf8Bytes(
                    (daoSettings?.daoName || daoDetails.daoName) +
                        (daoSettings?.description || daoDetails.description) +
                        (daoSettings?.logo || daoDetails.logo),
                ),
            ), // New DAO data as bytes
        };

        // console.log("Update DAO DAO Settings Constructor Params");
        // Encode the function call
        const encodedData = iface.encodeFunctionData("updateDaoSettings", [daoParams]);

        // Return the encoded data for further use
        return [daoAddress, 0, encodedData];
    }

    async function createUpdateGovernanceSettingsAction(governanceSetting, daoAddress) {
        // Define the contract's ABI fragment for the `updateGovernanceSettings` function
        const abiFragment = [
            "function updateGovernanceSettings((uint8 minimumParticipationPercentage, uint8 supportThresholdPercentage, uint32 minimumDurationForProposal, bool earlyExecution, bool canVoteChange) _governanceSettings)",
        ];

        // Define the contract interface using the ABI fragment
        const iface = new ethers.utils.Interface(abiFragment);

        // Define the parameters for the `GovernanceSettings` struct
        const governanceSettings = {
            minimumParticipationPercentage: governanceSetting?.minimumParticipation || daoDetails.minimumParticipation, // 20%
            supportThresholdPercentage: governanceSetting?.supportThreeshold || daoDetails.supportThreeshold, // 60%
            minimumDurationForProposal: governanceSetting?.minimumDuration || daoDetails.minimumDuration, // 1 day in seconds
            earlyExecution: governanceSetting?.earlyExecution || daoDetails.earlyExecution, // Early execution enabled
            canVoteChange: governanceSetting?.voteChange || daoDetails.voteChange, // Can vote change disabled
        };

        // Encode the function call with the struct (tuple) as parameter
        const encodedData = iface.encodeFunctionData("updateGovernanceSettings", [governanceSettings]);

        // Return the encoded data for further use
        return [daoAddress, 0, encodedData];
    }
    async function createRemoveDAOMembersAction(data, daoAddr) {
        const actions = [];
        // Define the ABI fragment for the removeDAOMembers function
        const abiFragment = ["function removeDAOMembers((address memberAddress, uint256 deposit)[] members) external"];
        console.log("going to interface");

        // Create the interface for the function
        const iface = new ethers.utils.Interface(abiFragment);

        const dataArray = Array.isArray(data) ? data : [data];
        for (let i of dataArray) {
            // const params = [i.address, parseInt(i.tokens)];
            // const encodedData = iface.encodeFunctionData("mintSupply", params);
            actions.push({
                memberAddress: i, // Example member address 1
                deposit: ethers.utils.parseEther(String(0)), // Example deposit 1 (1 Ether)
            });
        }
        console.log("going to iface");
        // Encode the function call with the array of structs as a parameter
        const encodedData = iface.encodeFunctionData("removeDAOMembers", [actions]);

        // Create the Action tuple array
        console.log("going to action");
        // Return the action tuple array
        return [
            [
                daoAddr, // Address of the DAO contract
                0, // Value in wei to send (usually 0 for function calls)
                encodedData, // Encoded function data
            ],
        ];
    }
    async function createUpdateMembersSettingsAction(memberSettings, daoAddress) {
        // Define the contract's ABI fragment for the `updateProposalMemberSettings` function
        const abiFragment = [
            "function updateProposalMemberSettings((bool isTokenBasedProposal, uint256 MinimumRequirement) _proposalCreationParams) public view",
        ];

        // Define the contract interface using the ABI fragment
        const iface = new ethers.utils.Interface(abiFragment);

        // Define the parameters for the `ProposalCreationSettings` struct
        const proposalCreationParams = {
            isTokenBasedProposal: true, // Example: true means token-based proposal
            MinimumRequirement: memberSettings.minimumRequirement || daoDetails.minimumRequirement, // Example: 1000 as minimum requirement
        };

        // Encode the function call with the struct as a tuple
        const encodedData = iface.encodeFunctionData("updateProposalMemberSettings", [proposalCreationParams]);

        // Return the encoded data for further use
        return [daoAddress, 0, encodedData];
    }

    async function createUpdateDAOAction(daoAddress) {
        const actions = [];
        const keyNames = Object.keys(proposalData.actions.settings);
        const daoSettings = {};
        const governanceSettings = {};
        const memberSettings = {};
        // console.log("Update DAO Settings Constructor Params");
        for (let i of keyNames) {
            if (i === "daoName" || i === "description" || i === "logo") {
                daoSettings[i] = proposalData.actions.settings[i];
            } else if (i === "minimumRequirement" || i === "proposalCreation") {
                governanceSettings[i] = proposalData.actions.settings[i];
            } else if (
                i === "supportThreeshold" ||
                i === "minimumParticipation" ||
                i === "minimumDuration" ||
                i === "earlyExecution" ||
                i === "voteChange"
            ) {
                memberSettings[i] = proposalData.actions.settings[i];
            }
        }
        if (Object.keys(daoSettings).length > 0) {
            const action = await createUpdateDAOSettingsAction(daoSettings, daoAddress);
            actions.push(action);
        }
        if (Object.keys(governanceSettings).length > 0) {
            const action = await createUpdateGovernanceSettingsAction(governanceSettings, daoAddress);
            actions.push(action);
        }
        if (Object.keys(memberSettings).length > 0) {
            const action = await createUpdateMembersSettingsAction(memberSettings, daoAddress);
            actions.push(action);
        }
        return actions;
    }
    async function createWithdrawAction(daoAddress, withdraw) {
        // ABI of the DAO contract containing the `withdrawTokens` function
        const daoABI = ["function withdrawTokens(address _token, address _from, address _to, uint256 _amount)"];

        // Create an interface from the ABI
        const daoInterface = new ethers.Interface(daoABI);
        const actions = [];
        for (let i of withdraw) {
            // Encode the function data for `withdrawTokens`
            const encodedData = daoInterface.encodeFunctionData("withdrawTokens", [i.tokenAddress, from, i.address, i.tokens]);

            const action = [
                daoAddress, // Address of the contract
                0, // Value in wei to send (usually 0 for function calls)
                encodedData, // Encoded function data
            ];
            actions.push(action);
        }

        return actions;
    }

    const getConstructorParams = useCallback(
        async (type, tokenAddress = "", daoAddress = "") => {
            try {
                if (type === "UT") {
                    return [
                        String(premintValue),
                        tokenSymbol,
                        tokenName,
                        owner,
                        [
                            mintData.can,
                            burnData.can,
                            pause.can,
                            blacklist.can,
                            changeOwner.can,
                            txTaxData.can,
                            buybackData.can,
                            true, //Rewards true/false
                        ],
                        txTaxData.can ? String(convertToBasisPoints(txTaxData.buy)) : String(convertToBasisPoints(0.05)),
                        userAddress,
                        String(fetchedData.decimals),
                    ];
                } else if (type === "GT") {
                    return [
                        tokenName,
                        tokenSymbol,
                        owner,
                        String(fetchedData.decimals),
                        [
                            mintData.can,
                            burnData.can,
                            pause.can,
                            true, // Stake
                            true, // Transfer
                            changeOwner.can,
                        ],
                    ];
                } else if (type === "ST") {
                    return [];
                } else if (type === "DAO") {
                    const members = [];
                    let flag = true;
                    if (daoData.participationInGovernance.participant === "tokenHolders") {
                        if (daoData.participationInGovernance.hasERC20Token === false) {
                            for (let i of daoData.participationInGovernance.distribution) {
                                if (i.address === userAddress) {
                                    flag = false;
                                }
                                // const json = [i.address, i.tokens];
                                const json = {
                                    memberAddress: i.address,
                                    deposit: i.tokens,
                                };
                                members.push(json);
                            }
                            if (flag) {
                                // members.push([userAddress, 1]);
                                members.push({
                                    memberAddress: userAddress,
                                    deposit: 1,
                                });
                            }
                        } else {
                            members.push({
                                memberAddress: userAddress,
                                deposit: 1,
                            });
                        }
                    } else {
                        for (let i of daoData.participationInGovernance.multisigMembersAddress) {
                            if (i === userAddress) {
                                flag = false;
                            }
                            // const json = [i.address, i.tokens];
                            const json = {
                                memberAddress: i,
                                deposit: 0,
                            };
                            members.push(json);
                        }
                        if (flag) {
                            // members.push([userAddress, 1]);
                            members.push({
                                memberAddress: userAddress,
                                deposit: 0,
                            });
                        }
                    }
                    const daoParams = [
                        daoData.describe.daoName,
                        ethers.utils.hexlify(
                            ethers.utils.toUtf8Bytes(daoData.describe.daoName + daoData.describe.description + daoData.describe.logo),
                        ),
                    ];
                    const governanceTokenParams = [
                        daoData.participationInGovernance.tokenName,
                        daoData.participationInGovernance.tokenSymbol,
                        userAddress,
                    ];
                    const minimumDuration = convertJsonToTimeStamp(daoData.governanceSettings.minimumDuration);
                    const governanceSettingsConstruct = [
                        daoData.governanceSettings.minimumParticipation,
                        daoData.governanceSettings.supportThreeshold,
                        minimumDuration,
                        daoData.governanceSettings.earlyExecution,
                        daoData.governanceSettings.voteChange,
                    ];
                    const proposalCreationParamsConstruct = [daoData.daoType === "token", daoData.governanceSettings.minimumRequirement];
                    return [
                        daoParams,
                        daoData.participationInGovernance?.contractAddress === ""
                            ? "0x0000000000000000000000000000000000000000"
                            : daoData.participationInGovernance.contractAddress,
                        governanceTokenParams,
                        governanceSettingsConstruct,
                        members,
                        proposalCreationParamsConstruct,
                        daoData.participationInGovernance.participant === "multisigMembers",
                    ];
                } else if (type === "proposal") {
                    // const mintActions = await createTransferAction(proposalData.actions.mint, tokenAddress);
                    const actions =
                        proposalData.actions?.mint.length > 0
                            ? await createTransferAction(proposalData.actions.mint, daoAddress)
                            : proposalData.actions?.withdraw.length > 0
                              ? await createWithdrawAction(daoAddress, proposalData.actions.withdraw)
                              : Object.keys(proposalData.actions?.settings).length > 0
                                ? await createUpdateDAOAction(daoAddress)
                                : proposalData.actions?.addWallets.length > 0
                                  ? await createAddWalletAction(proposalData.actions.addWallets, daoAddress)
                                  : proposalData.actions?.removeWallets.length > 0
                                    ? await createRemoveDAOMembersAction(proposalData.actions.removeWallets, daoAddress)
                                    : [];
                    const actionId =
                        proposalData.actions?.mint.length > 0
                            ? 0
                            : proposalData.actions?.withdraw.length > 0
                              ? 1
                              : Object.keys(proposalData.actions?.settings).length > 0
                                ? 4
                                : 2;
                    return [
                        daoAddress,
                        proposalData.proposalMetaData.proposalTitle,
                        proposalData.proposalMetaData.proposalDescription,
                        daoDetails.minimumApproval,
                        parseInt(proposalData.voting.startTime / 1000), //Start Time in timestamp
                        parseInt((proposalData.voting.endTime - proposalData.voting.startTime) / 1000), //Duration in timestamp
                        actionId,
                        actions,
                    ];
                } else {
                    return [];
                }
            } catch (e) {
                console.log(e);
                showErrorToast("Contract not deployed");
            }
        },
        [premintValue, tokenSymbol, tokenName, owner, mintData.can, burnData.can, pause.can, blacklist.can, changeOwner.can, txTaxData, buybackData],
    );

    const compileERC20Contract = async () => {
        try {
            dispatch(updateLoader(true));
            const solidityCompilerRes = await getFilteredEntities(
                {
                    name: "solidityCompiler",
                },
                TEDAAS_SCHEMAS.urlId,
                TEDAAS_SCHEMAS.accessToken,
            );
            const solidityCompilerUrl = solidityCompilerRes?.entities[0]?.url;
            const data = {
                contract: ERC20Code(tokenName),
                name: tokenName,
            };
            const res = await axios.post(`${solidityCompilerUrl}/create-erc20-contract`, data);
            const { abi, bytecode } = res.data;
            // console.log(abi, bytecode, "ABI BYTECODE");
            await deployERC20Contract(abi, bytecode, "UT");
            dispatch(updateLoader(false));
            return true;
        } catch (e) {
            console.log(e);
            showErrorToast("Contract not deployed");
            dispatch(updateLoader(false));
            return false;
        }
    };

    const compileGovernanceTokenContract = async () => {
        try {
            dispatch(updateLoader(true));
            const solidityCompilerRes = await getFilteredEntities(
                {
                    name: "solidityCompiler",
                },
                TEDAAS_SCHEMAS.urlId,
                TEDAAS_SCHEMAS.accessToken,
            );
            const solidityCompilerUrl = solidityCompilerRes?.entities[0]?.url;
            const data = {
                contract: GovernanceTokenCode(tokenName),
                name: tokenName,
            };
            const res = await axios.post(`${solidityCompilerUrl}/create-gt-contract`, data);
            const { abi, bytecode } = res.data;
            // console.log(abi, bytecode, "ABI BYTECODE");
            await deployERC20Contract(abi, bytecode, "GT");
            dispatch(updateLoader(false));
            return true;
        } catch (e) {
            console.log(e);
            showErrorToast("Contract not deployed");
            dispatch(updateLoader(false));
            return false;
        }
    };

    const compileSecurityTokenContract = async () => {
        try {
            dispatch(updateLoader(true));
            // const solidityCompilerRes = await getFilteredEntities(
            //     {
            //         name: "solidityCompiler",
            //     },
            //     TEDAAS_SCHEMAS.urlId,
            //     TEDAAS_SCHEMAS.accessToken,
            // );
            // const solidityCompilerUrl = solidityCompilerRes?.entities[0]?.url;
            // const data = {
            //     contract: GovernanceTokenCode(tokenName),
            //     name: tokenName,
            // };
            // const res = await axios.post(`${solidityCompilerUrl}/create-gt-contract`, data);
            // const { abi, bytecode } = res.data;
            const abi = securityToken.abi;
            const bytecode = securityToken.bytecode;
            // console.log(abi, bytecode, "ABI BYTECODE");
            await deployERC20Contract(abi, bytecode, "ST");
            dispatch(updateLoader(false));
            return true;
        } catch (e) {
            console.log(e);
            showErrorToast("Contract not deployed");
            dispatch(updateLoader(false));
            return false;
        }
    };

    const deployERC20Contract = async (abi, bytecode, type) => {
        try {
            if (signingAddress && userAddress) {
                // console.log("COntranct factory");
                const contractFactory = new ethers.ContractFactory(abi, bytecode, signingAddress);
                const constructorParams = await getConstructorParams(type);
                // console.log("COntranct deploy", constructorParams);
                const contract = await contractFactory.deploy(...constructorParams);
                // console.log("COntranct deploy done");
                await contract.deployed();
                const decimalValue = parseInt(contract.deployTransaction.gasPrice, 16);
                const GasgweiValue = decimalValue / 1e9;
                const contractVerificationParams = { ...UT_CONTRACT_VERIFICATION };
                contractVerificationParams.contractaddress = contract.address;

                const utSourceCode = await fetch(utSourceCodeTxtFile)
                    .then((r) => r.text())
                    .then((text) => {
                        return text;
                    });
                contractVerificationParams.sourceCode = utSourceCode;
                const resVerification = await verifyContractViaEtherscan(contractVerificationParams);
                await postTokenDetails(contract.address, contract.deployTransaction.hash, GasgweiValue.toFixed(2), tokenName, abi);
                if (fetchedData.tokenType === "ST") {
                    console.log("Initilizing Security Token");
                    console.log(
                        "0xaa2B361A3d1ba8D09C397e931e2e07EF282a0E6a",
                        "0x473E8fcfEe7850824B8d8FA2921D62a98eC674C6",
                        tokenName,
                        tokenSymbol,
                        String(fetchedData.decimals),
                        String(premintValue),
                    );
                    const tx = await contract.init(
                        "0xaa2B361A3d1ba8D09C397e931e2e07EF282a0E6a",
                        "0x473E8fcfEe7850824B8d8FA2921D62a98eC674C6",
                        tokenName,
                        tokenSymbol,
                        String(fetchedData.decimals),
                        String(premintValue),
                    );
                    console.log("Security Token Initialized1");
                    await tx.wait();
                    console.log("Security Token Initialized");
                }
                dispatch(tokenCreationNodeEdgeReset());
                dispatch(tokenCreationReset());
            } else {
                showWarningToast("Invalid Signing Address, Reload the page!");
            }
        } catch (e) {
            console.log(e);
            // showErrorToast("Contract not deployed");
            throw Error("Error in deploying contract/n", e);
        }
    };

    return { getConstructorParams, compileERC20Contract, compileGovernanceTokenContract, compileSecurityTokenContract };
};

export default useDeployContract;
