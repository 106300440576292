export const SET_USER_ADDRESS = "SET_USER_ADDRESS";
export const SET_CURRENT_CHAIN = "SET_CURRENT_CHAIN";
export const SET_SIGNER = "SET_SIGNER";
export const UPDATE_WALLET_AVAILABLE = "UPDATE_WALLET_AVAILABLE";

export const FETCH_DATA_SUCCESS = "FETCH_DATA_SUCCESS";
export const UPDATE_USER_TOKEN_DETAILS = "UPDATE_USER_TOKEN_DETAILS";
export const UPDATE_TOKEN_META_DATA = "UPDATE_TOKEN_META_DATA";
export const UPDATE_USER_TOKEN_TRANSACTIONS = "UPDATE_USER_TOKEN_TRANSACTIONS";
export const UPDATE_USER_ALL_TOKEN = "UPDATE_USER_ALL_TOKEN";
export const POST_DATA_SUCCESS = "POST_DATA_SUCCESS";
export const UPDATE_DATA_SUCESS = "UPDATE_DATA_SUCESS";
export const RESET_AUTH_STATE = "RESET_AUTH_STATE";

export const FETCH_TX_SUCCESS = "FETCH_TX_SUCCESS";
export const POST_TX_SUCCESS = "POST_TX_SUCCESS";
export const SET_CURRENT_TOKEN_ADDRESS = "SET_CURRENT_TOKEN_ADDRESS";

export const UPDATE_TOKEN_FUNCTIONS = "UPDATE_TOKEN_FUNCTIONS";

export const UPDATE_MINT_SCHEDULE_DATA = "UPDATE_MINT_SCHEDULE_DATA";
export const UPDATE_MINT_PERCENTAGE = "UPDATE_MINT_PERCENTAGE";
export const UPDATE_CANMINT_TOGGLE = "UPDATE_CANMINT_TOGGLE";
export const UPDATE_MINT_SCHEDULE_TENURE = "UPDATE_MINT_SCHEDULE_TENURE";
export const UPDATE_MINT_SCHEUDLE_TENURE_TYPE = "UPDATE_MINT_SCHEUDLE_TENURE_TYPE";

export const UPDATE_BURN_SCHEDULE_DATA = "UPDATE_BURN_SCHEDULE_DATA";
export const UPDATE_BURN_PERCENTAGE = "UPDATE_BURN_PERCENTAGE";
export const UPDATE_CANBURN_TOGGLE = "UPDATE_CANBURN_TOGGLE";
export const UPDATE_BURN_SCHEDULE_TENURE = "UPDATE_BURN_SCHEDULE_TENURE";
export const UPDATE_BURN_SCHEUDLE_TENURE_TYPE = "UPDATE_BURN_SCHEUDLE_TENURE_TYPE";

export const UPDATE_PRE_MINT_AMOUNT = "UPDATE_PRE_MINT_AMOUNT";

export const ADVISORS = "Advisors";
export const TREASURY = "Treasury";
export const MARKETING = "Marketing";
export const REWARD = "Rewards";
export const TEAM = "Team";

export const UPDATE_EMISSION_DATA = "UPDATE_EMISSION_DATA";
export const UPDATE_EMISSION_DATA_VALUE = "UPDATE_EMISSION_DATA_VALUE";
export const UPDATE_EMISSION_CATEGORY = "UPDATE_EMISSION_CATEGORY";
export const UPDATE_EMISSION_MAX_INTERVAL = "UPDATE_EMISSION_MAX_INTERVAL";

export const STAKING = "Staking";
export const INCENTIVES = "Incentives";
export const BUSINESS_REWARDS = "Business Rewards";

export const UPDATE_REWARD_CATERGORY = "UPDATE_REWARD_CATERGORY";
export const UPDATE_REWARD_DATA = "UPDATE_REWARD_DATA";
export const UPDATE_REWARD_DATA_VALUE = "UPDATE_REWARD_DATA_VALUE";
export const UPDATE_REWARD_SCHEDULE_TENURE = "UPDATE_REWARD_SCHEDULE_TENURE";
export const UPDATE_REWARD_SCHEDULE_TENURE_TYPE = "UPDATE_REWARD_SCHEDULE_TENURE_TYPE";

export const UPDATE_CAN_PAUSE = "UPDATE_CAN_PAUSE";
export const UPDATE_BLACKLIST = "UPDATE_BLACKLIST";
export const UPDATE_CHANGE_OWNER = "UPDATE_CHANGE_OWNER";

export const UPDATE_CAN_TX_TAX = "UPDATE_CAN_TX_TAX";
export const UPDATE_TX_TAX_TYPE = "UPDATE_TX_TAX_TYPE";
export const UPDATE_BUY_TX_TAX_VAL = "UPDATE_BUY_TX_TAX_VAL";
export const UPDATE_SELL_TX_TAX_VAL = "UPDATE_SELL_TX_TAX_VAL";
export const UPDATE_TX_TAX_VAL = "UPDATE_TX_TAX_VAL";
export const UPDATE_TX_RECIEVER_ADDR = "UPDATE_TX_RECIEVER_ADDR";

export const UPDATE_CAN_BUYBACK = "UPDATE_CAN_BUYBACK";
export const UPDATE_BUYBACK_VAL = "UPDATE_BUYBACK_VAL";
export const UPDATE_BUYBACK_RECIEVER_ADDR = "UPDATE_BUYBACK_RECIEVER_ADDR";

export const INC_STEP = "INC_STEP";
export const DEC_STEP = "DEC_STEP";
export const UPDATE_STEP = "UPDATE_STEP";

export const UPDATE_TOKEN_UTILITIES = "UPDATE_TOKEN_UTILITIES";

export const UPDATE_TOKEN_TYPE = "UPDATE_TOKEN_TYPE";
export const UPDATE_TOKEN_NAME = "UPDATE_TOKEN_NAME";
export const UPDATE_TOKEN_SYMBOL = "UPDATE_TOKEN_SYMBOL";
export const UPDATE_TOKEN_DECIMALS = "UPDATE_TOKEN_DECIMALS";
export const UPDATE_TOKEN_OWNER = "UPDATE_TOKEN_OWNER";
export const UPDATE_TOKEN_PRICE = "UPDATE_TOKEN_PRICE";
export const UPDATE_TOKEN_VALUATION = "UPDATE_TOKEN_VALUATION";
export const SET_TOKEN_PAUSED_BLOCKCHAIN = "SET_TOKEN_PAUSED";
export const UPDATE_STAKED_AMOUNT_BLOCKCHAIN = "UPDATE_STAKED_AMOUNT";
export const UPDATE_BALANCE_AMOUNT_BLOCKCHAIN = "UPDATE_BALANCE_AMOUNT";
export const UPDATE_TOTAL_SUPPLY_BLOCKCHAIN = "UPDATE_TOTAL_SUPPLY";
export const UPDATE_TXN_TAX_WALLET = "UPDATE_TXN_TAX_WALLET";

export const UPDATE_MODAL_ACTION = "UPDATE_MODAL_ACTION";

export const USER_ALL_TOKEN_DETAILS = "USER_ALL_TOKEN_DETAILS";

export const UPDATE_PAGE_NAME = "UPDATE_PAGE_NAME";

export const UPDATE_PAGE_BACKGROUND = "UPDATE_PAGE_BACKGROUND";

export const UPDATE_PAGE_WIDTH = "UPDATE_PAGE_WIDTH";

export const UPDATE_TOP_BAR_STYLE = "UDPATE_TOP_BAR_STYLE";

export const SET_LOADER = "SET_LOADER";

export const switchCaseConstants = {
    MINT: "mint",
    BURN: "burn",
    EMISSION: "Emission",
    PERCENTAGE: "Percentage",
    REWARDS: "Rewards",
    PREMINTVALUE: "preMintValue",
    BUYTXTAX: "buyTxTax",
    SELLTXTAX: "sellTxTax",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    YEARLY: "Yearly",
    PERCENTAGE: "Percentage",
    LOCKING_MONTHS: "Locking Months",
    UNLOCKING_MONTHS: "Unlocking Months",
    APR: "APR Percentage",
    ACTIVE: "active",
    TENURE_TYPE: "Tenure Type",
    STAKING: "Staking",
    TRANSACTION_TAX: "transactionTax",
    REWARDS_TOKEN: "rewardsToken",
    INCENTIVES: "Incentives",
    BUSINESS_REWARDS: "Business Rewards",
    REWARD_PARAMS: "Reward Params",
    INCENTIVE_PARAMS: "Incentive Params",
    BUSINESS_REWARD_PARAMS: "Business Rewards Params",
    QUOTA: "Quota",
    VALIDITY: "validity",
    QUOTA_PERCENTAGE: "quotaPercentage",
    TOKEN_PRICE: "tokenPrice",
    BUYBACK: "buyback",
    ADVISORS: "Advisors",
    TEAM: "Team",
    REWARDS: "Rewards",
    TREASURY: "Treasury",
    MARKETING: "Marketing",
    PAGENAME: "pageName",
    BACKGROUND: "background",
    PAGEWIDTH: "pagewidth",
    TOPBARSTYLE: "topbarstyle",
};

export const actionModalConstants = {
    MINT: "Mint",
    BURN: "Burn",
    PAUSE: "Pause",
    BLACKLIST: "Blacklist",
    WHITELIST: "Whitelist",
    TAXTXN: "Taxable Transaction",
    BUYBACK: "Buyback",
    ALLOCATIONS: "Allocations",
    TRANSFER: "Transfer",
    APPROVE: "Approve",
    CHANGEOWNER: "Change Owner",
    EDITTXFEES: "Edit Tx Fees",
    BLACKLISTADDRESS: "Blacklist Addresses",
    EDITBURNRATE: "Edit Burn Rate",
    STAKE: "Stake",
    EDITTOKENDOCS: "Edit Token Docs",
    WITHDRAW: "Withdraw",
    WITHDRAWFULL: "Withdraw Full",
    CHANGEAPR: "Change APR",
};

export const editorOptions = {
    acceptSuggestionOnCommitCharacter: true,
    acceptSuggestionOnEnter: "on",
    accessibilitySupport: "auto",
    autoIndent: false,
    automaticLayout: true,
    codeLens: true,
    colorDecorators: true,
    contextmenu: true,
    cursorBlinking: "blink",
    cursorSmoothCaretAnimation: false,
    cursorStyle: "line",
    disableLayerHinting: false,
    disableMonospaceOptimizations: false,
    dragAndDrop: false,
    fixedOverflowWidgets: false,
    folding: true,
    foldingStrategy: "auto",
    fontLigatures: true,
    formatOnPaste: false,
    formatOnType: false,
    hideCursorInOverviewRuler: false,
    highlightActiveIndentGuide: false,
    links: true,
    mouseWheelZoom: false,
    multiCursorMergeOverlapping: true,
    multiCursorModifier: "alt",
    overviewRulerBorder: true,
    overviewRulerLanes: 2,
    quickSuggestions: true,
    quickSuggestionsDelay: 100,
    readOnly: true,
    renderControlCharacters: false,
    renderFinalNewline: true,
    renderIndentGuides: true,
    renderLineHighlight: "none",
    renderWhitespace: "none",
    revealHorizontalRightPadding: 30,
    roundedSelection: true,
    rulers: [],
    scrollBeyondLastColumn: 5,
    scrollBeyondLastLine: false,
    selectOnLineNumbers: true,
    selectionClipboard: true,
    selectionHighlight: false,
    showFoldingControls: "click",
    smoothScrolling: true,
    suggestOnTriggerCharacters: true,
    wordBasedSuggestions: true,
    wordSeparators: "~!@#$%^&*()-=+[{]}|;:'\",.<>/?",
    wordWrap: "off",
    wordWrapBreakAfterCharacters: "\t})]?|&,;",
    wordWrapBreakBeforeCharacters: "{([+",
    wordWrapBreakObtrusiveCharacters: ".",
    wordWrapColumn: 80,
    wordWrapMinified: true,
    wrappingIndent: "none",
    scrollbar: { vertical: "hidden" },
    lineNumbers: "off",
    loader: true,
    // fontFamily: 'Manrope',
    // fontWeight: 600
};

export const chainOptions = [
    {
        name: "Ethereum",
        id: "0x1",
        chainName: "Ethereum Mainnet",
        rpcUrls: ["https://eth.llamarpc.com"],
        blockExplorerUrls: ["https://etherscan.io"],
        currencySymbol: "ETH",
        currencyName: "Ether",
        type: "mainnet",
    },
    {
        name: "Sepolia",
        id: "0xaa36a7",
        chainName: "Sepolia test network",
        rpcUrls: ["https://sepolia.infura.io/v3/"],
        blockExplorerUrls: ["https://sepolia.etherscan.io"],
        currencySymbol: "SepoliaETH",
        currencyName: "SepoliaETH",
        type: "testnet",
    },
    {
        name: "Polygon",
        id: "0x89",
        chainName: "Polygon Mainnet",
        rpcUrls: ["https://polygon-rpc.com/"],
        blockExplorerUrls: ["https://polygonscan.com/"],
        currencySymbol: "MATIC",
        currencyName: "MATIC",
        type: "testnet",
    },
    {
        name: "Mobius Private Blockchain",
        id: "0x539",
        chainName: "Mobius",
        rpcUrls: ["https://mobius-besu-rpc.gov-cloud.ai/"],
        blockExplorerUrls: ["https://mobius-blockscout.gov-cloud.ai/"],
        currencyName: "Ether",
        currencySymbol: "ETH",
        type: "testnet",
    },
    {
        name: "Hardhat Testnet",
        id: "0x7a69",
        chainName: "Hardhat Testnet",
        rpcUrls: ["http://127.0.0.1:8545/"],
        blockExplorerUrls: ["https://etherscan.io"],
        currencySymbol: "ETH",
        currencyName: "ETH",
        type: "testnet",
    },
    {
        name: "Disconnect",
        id: null,
    },
];

export const daoFormSteps = [
    {
        name: "Select Blockchain",
        desc: "The chain you choose is where your tokens and assets are stored.",
        link: "Blockchain for your DAO",
        url: "https://aidtaas.com",
    },
    {
        name: "Describe your DAO",
        desc: "Name and define your DAO so new contributors know they've come to the right place.",
        link: "Read our guide",
        url: "https://aidtaas.com",
    },
    {
        name: "Define membership",
        desc: "Decide the type of voting your DAO uses. for help deciding which type of governance is best",
        link: "Read our guide",
        url: "https://aidtaas.com",
    },
    {
        name: "Select governance settings",
        desc: "These are the rules that define how decisions are made in your DAO.",
        link: "Learn more",
        url: "https://aidtaas.com",
    },
    {
        name: "Deploy your DAO",
        desc: "Choose all the plans and with AI assistance create a one page buisness proposal",
    },
];

export const CURRENCY_OPTIONS = [
    {
        name: "US Dollars",
        code: "USD",
        symbol: "$",
    },
    {
        name: "Indian Rupee",
        code: "INR",
        symbol: "₹",
    },
    {
        name: "Digital Rupee",
        code: "INR",
        symbol: "e₹",
    },
    {
        name: "Euro",
        code: "EUR",
        symbol: "€",
    },
    {
        name: "British Pound Sterling",
        code: "GBP",
        symbol: "£",
    },
];

// export const JSON_SERVER_ENDPOINT ='https://xpx-json-server.onrender.com';
export const JSON_SERVER_TEDAAS_USERS = "tedaasUsers";
export const JSON_SERVER_TEDAAS_TRANSACTIONS = "tedaasTransactions";
export const JSON_SERVER_TEDAAS_TOKENS = "tedaasTokens";
export const JSON_SERVER_ENDPOINT = "http://localhost:3001";
export const SMART_CONTRACT_COMPILER_ENDPOINT = "https://ig.aidtaas.com/solidity-compiler";
export const SMART_CONTRACT_COMPILER_ENDPOINT_LOCAL = "http://localhost:8080";
export const SMART_CONTRACT_COMPILER_ENDPOINT_NGROK = "https://4444-183-82-106-180.ngrok-free.app";

export const FETCH_TOKEN_CREATION = "fetchTokenCreation";
export const FETCH_TOKEN_CREATION_ASSIGNEE = "fetchTokenCreationAssignee";
export const FETCH_TOKEN_CREATION_REWARDS = "fetchTokenCreationRewards";
export const CONFIGURE_TOKEN_BURN = "configureTokenBurn";
export const CONFIGURE_TOKEN = "configureToken";
export const CONFIGURE_TOKEN_VAL = "configureTokenValue";
export const TOKEN_CREATION_RESET = "tokenCreationReset";
export const CONFIGURE_TOKEN_TYPE = "configureTokenType";
export const CONFIGURE_TOKEN_OPTIONS = ["burn", "pause", "mint", "transactionTax", "buyback", "blacklist", "changeOwner"];
export const TOKEN_CREATION_ASSIGNEE = "tokenCreationAssignee";
export const TOKEN_CREATION_BURN = "tokenCreationBurn";
export const TOKEN_CREATION_MINT = "tokenCreationMint";
export const TOKEN_CREATION_DELETE_ASSIGNEE = "tokenCreationDeleteAssignee";
export const TOKEN_CREATION_REWARD = "tokenCreationReward";
export const TOKEN_CREATION_DELETE_REWARD = "tokenCreationDeleteReward";
export const TOKEN_CREATION_TOKEN_TYPE = "tokenCreationTokenType";
export const TOKEN_CREATION_MODEL = "tokenCreationModel";
export const TOKEN_ACTION_MODAL = "tokenActionModal";
export const TOKEN_CREATION = "tokenCreation";
export const TOKEN_CREATION_DRAG = "tokenCreationDrag";
export const TOKEN_TOP_BAR_NAME_CHANGE = "tokenTopBarNameChange";
export const TOKEN_TOP_BAR_BACKGROUND_CHANGE = "tokenTopBarBackgroundChange";
export const TOKEN_CREATION_NODE = "tokenCreationNode";
export const TOKEN_CREATION_EDGE = "tokenCreationEdge";
export const TOKEN_CREATION_NODE_EDGE_RESET = "tokenCreationNodeEdgeReset";

export const TOKEN_SIMULATION = {
    mint: "mint",
    burn: "burn",
    tokenomics: "tokenomics",
    reward: "reward",
    emission: "emission",
    action: "tokenSimulationAction",
    headerOptions: "headerOptions",
    header: "tokenSimulationHeaderOptions",
};
// export const TOKEN_SIMULATION_TOKENOMICS = "tokenSimulationTokenomics";
// export const TOKEN_SIMULATION_REWARD_DISTRIBUTION = "tokenSimulationRewardDistribution";
// export const TOKEN_SIMULATION_TOKEN_BURN = "tokenSimulationTokenBurn";
// export const TOKEN_SIMULATION_TOKEN_MINT = "tokenSimulationTokenMint";
// export const TOKEN_SIMULATION_EMISSION_SCHEDULE = "tokenSimulationEmissionSchedule";
export const ITEM_COLORS = ["#FBC96C", "#A6AF88", "#CBC3EE", "#9A9AFF", "#94D0FF", "#F1AE9D", "#E3E1DE"];

export const RIGHT_SIDE_BAR = "rightSideBar";

export const NODES = {
    tokenType: {
        name: "Token Type",
        nodes: [
            {
                name: "Utility",
                image: "user",
                desc: "Utility Token",
                index: "UT",
                type: "tokenType",
                subType: "tokenType",
            },
            {
                name: "Non-Fungible",
                image: "user",
                desc: "Non-Fungible Token",
                index: "NFT",
                type: "tokenType",
                subType: "tokenType",
            },
            {
                name: "Security",
                image: "user",
                desc: "Security Token",
                index: "ST",
                type: "tokenType",
                subType: "tokenType",
            },
            {
                name: "Governance",
                image: "user",
                desc: "Governance Token",
                index: "GT",
                type: "tokenType",
                subType: "tokenType",
            },
        ],
    },
    tokenConfiguration: {
        name: "Configure Token",
        nodes: [
            {
                name: "Burn",
                image: "user",
                desc: "Burn",
                index: "burn",
                type: "tokenConfiguration",
                subType: "percent",
            },
            {
                name: "Mint",
                image: "user",
                desc: "Mint",
                index: "mint",
                type: "tokenConfiguration",
                subType: "percent",
            },
            {
                name: "Change Owner",
                image: "user",
                desc: "Change Owner",
                index: "changeOwner",
                type: "tokenConfiguration",
                subType: "booleanAddress",
            },
            {
                name: "Buyback",
                image: "user",
                desc: "Buyback",
                index: "buyback",
                type: "tokenConfiguration",
                subType: "percentAddress",
            },
            {
                name: "Transaction Tax",
                image: "user",
                desc: "Transaction Tax",
                index: "transactionTax",
                type: "tokenConfiguration",
                subType: "tax",
            },
            {
                name: "Pause",
                image: "user",
                desc: "Pause",
                index: "pause",
                type: "tokenConfiguration",
                subType: "boolean",
            },
            {
                name: "Blacklist",
                image: "user",
                desc: "Blacklist",
                index: "blacklist",
                type: "tokenConfiguration",
                subType: "boolean",
            },
        ],
    },
    assignee: {
        name: "Assignee",
        nodes: [
            {
                name: "Add Custom",
                image: "user",
                desc: "Add Custom",
                index: "custom",
                type: "assignee",
                subType: "add",
            },
            {
                name: "Team",
                image: "user",
                desc: "Team",
                index: "team",
                type: "assignee",
                subType: "percent",
            },
            {
                name: "Advisor",
                image: "user",
                desc: "Advisor",
                index: "advisor",
                type: "assignee",
                subType: "percent",
            },
            {
                name: "Marketor",
                image: "user",
                desc: "Marketor",
                index: "marketor",
                type: "assignee",
                subType: "percent",
            },
            {
                name: "Reward",
                image: "user",
                desc: "Reward",
                index: "reward",
                type: "assignee",
                subType: "percent",
            },
        ],
    },
    reward: {
        name: "Reward",
        nodes: [
            {
                name: "Add Custom",
                image: "user",
                desc: "Add Custom",
                index: "custom",
                type: "reward",
                subType: "add",
            },
            {
                name: "Incentive",
                image: "user",
                desc: "Incentive",
                index: "incentive",
                type: "reward",
                subType: "percent",
            },
            {
                name: "Business Reward",
                image: "user",
                desc: "Business Reward",
                index: "businessReward",
                type: "reward",
                subType: "percent",
            },
            {
                name: "Staking",
                image: "user",
                desc: "Staking",
                index: "staking",
                type: "reward",
                subType: "percent",
            },
        ],
    },
    tokenPrice: {
        name: "Token Price",
        nodes: [
            {
                name: "Token Price",
                image: "user",
                desc: "Token Price",
                index: "tokenPrice",
                type: "tokenPrice",
                subType: "tokenPrice",
            },
        ],
    },
    tokenDocs: {
        name: "Token Documentation",
        nodes: [
            {
                name: "Token Docs",
                image: "user",
                desc: "Token Docs",
                index: "tokenDocs",
                type: "tokenDocs",
                subType: "tokenDocs",
            },
        ],
    },
};

export const DAO_CREATION_REDUCER = {
    BLOCKCHAIN: "blockchain",
    DESCRIBE: "describe",
    PARTICIPATION_IN_GOVERNANCE: "participationInGovernance",
    GOVERNANCE_SETTING: "governanceSettings",
    CREATED_AT: "createdAt",
    UPDATED_AT: "updatedAt",
    OWNER_ADDRESS: "ownerAddress",
    DAO_ADDRESS: "daoAddress",
    DAO_TYPE: "daoType",
    CONFIGURE_VAL: "DAO_CREATION_REDUCER_CONFIGURE_VAL",
    RESET: "DAO_CREATION_REDUCER_RESET",
};

export const PROPOSALS_ACTIONS_REDUCER = {
    CONFIGURE_VAL: "PROPOSAL_ACTIONS_REDUCER_CONFIGURE_VAL",
};

export const DAO_DATA_REDUCER = {
    CONFIGURE_VAL: "DAO_DATA_REDUCER_CONFIGURE_VAL",
};

export const PROPOSAL_CREATION_REDUCER = {
    CONFIGURE_VAL: "PROPOSAL_CREATION_REDUCER_CONFIGURE_VAL",
    RESET: "PROPOSAL_CREATION_REDUCER_RESET",
};

export const PROPOSAL_DATA_REDUCER = {
    CONFIGURE_VAL: "PROPOSAL_DATA_REDUCER_CONFIGURE_VAL",
};

export const TOKEN_DATA_REDUCER = {
    CONFIGURE_VAL: "TOKEN_DATA_REDUCER_CONFIGURE_VAL",
};

export const SELECT_BLOCKCHAIN_OPTIONS = {
    testnet: [
        {
            name: "Ethereum Sepolia",
            value: "ethereumSepolia",
            blockchain: "L1 Blockchain",
        },
        {
            name: "ZkSync Sepolia",
            value: "zksyncSepolia",
            blockchain: "L2 Blockchain",
        },
    ],
    mainnet: [
        {
            name: "Ethereum",
            value: "ethereum",
            blockchain: "L1 Blockchain",
        },
        {
            name: "Polygon",
            value: "polygon",
            blockchain: "L2 Blockchain",
        },
        {
            name: "Arbitrum",
            value: "arbitrum",
            blockchain: "L2 Blockchain",
        },
        {
            name: "Base",
            value: "base",
            blockchain: "L2 Blockchain",
        },
        {
            name: "ZKsync Era",
            value: "zksyncEra",
            blockchain: "L2 Blockchain",
        },
    ],
};

export const TEDAAS_SCHEMAS = {
    accessToken:
        "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Ny1NUVdFRTNHZE5adGlsWU5IYmpsa2dVSkpaWUJWVmN1UmFZdHl5ejFjIn0.eyJleHAiOjE3MjI5NzU4MDMsImlhdCI6MTcyMjkzOTgwMywianRpIjoiNmRkYTlmNDItMjU3Ni00Y2ZjLTk4ODQtNjExZWU0NjE3NjgyIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJCT0xUWk1BTk5fQk9UIiwiUEFTQ0FMX0lOVEVMTElHRU5DRSIsIkhPTEFDUkFDWSIsImFjY291bnQiXSwic3ViIjoiYmE3Nzg5NmYtN2MxZi00NTAxLWI0ZjYtNTQ3YTdkMjZkZGU2IiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiTU9ORVQiLCJzZXNzaW9uX3N0YXRlIjoiNzk3NTBiODYtODQ2MS00YzlhLTgzYjAtOTM2ZWNmNjE5ZGJhIiwibmFtZSI6Ik1vYml1cyB4cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZ2l2ZW5fbmFtZSI6Ik1vYml1cyIsImZhbWlseV9uYW1lIjoieHB4QGdhaWFuc29sdXRpb25zLmNvbSIsInByZWZlcnJlZF91c2VybmFtZSI6InBhc3N3b3JkX3RlbmFudF94cHhAZ2FpYW5zb2x1dGlvbnMuY29tIiwiZW1haWwiOiJwYXNzd29yZF90ZW5hbnRfeHB4QGdhaWFuc29sdXRpb25zLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhY3IiOiIxIiwicmVhbG1fYWNjZXNzIjp7InJvbGVzIjpbImRlZmF1bHQtcm9sZXMtbWFzdGVyIiwib2ZmbGluZV9hY2Nlc3MiLCJ1bWFfYXV0aG9yaXphdGlvbiJdfSwicmVzb3VyY2VfYWNjZXNzIjp7IkJPTFRaTUFOTl9CT1QiOnsicm9sZXMiOlsiQk9MVFpNQU5OX0JPVF9VU0VSIl19LCJQQVNDQUxfSU5URUxMSUdFTkNFIjp7InJvbGVzIjpbIlBBU0NBTF9JTlRFTExJR0VOQ0VfVVNFUiJdfSwiSE9MQUNSQUNZIjp7InJvbGVzIjpbIkhPTEFDUkFDWV9VU0VSIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiI3OTc1MGI4Ni04NDYxLTRjOWEtODNiMC05MzZlY2Y2MTlkYmEiLCJ0ZW5hbnRJZCI6ImJhNzc4OTZmLTdjMWYtNDUwMS1iNGY2LTU0N2E3ZDI2ZGRlNiJ9.aLICxII7TJJTMzFnE9Shhjn_wxGHek_8cVCaFnX8U5igTqcWvMHnfHcJytSLUcrq7bVBO4y3gQsQCjJlWpe8yYTy5rQ4hp7O9DljJyM3vqEa_j2IwELUW3cpgQoJw4X68jX4zMbqNomIDYHQleA144k0jf0ZvsqDWaTTpFZIM505GP9771BfrQzm3EyNWz8pEPig_MtvnnTH4qMtBQrYW1CWkE4eueSwZcv18i8Gf9vSLJKV6v0woxfZtEv8XgLn6CwtbEasX_J3BofqBB0lV7_v-siQuReSCT1CWIyRgzthUp2zVFg96vudQzMYCJtQosKmtBzYiM5mW3ZPdyWnlQ",
    universeId: "66e2b17b5e926d5eb77c114b",
    transactionId: "66e2b1984006bd33cd1a37c4",
    tokenId: "66e2b2f7f604240f964045e9",
    tokenHoldersId: "66e2c068f604240f964045ea",
    urlId: "66f0f06ff604240f96404649",
    daoId: "673477399bc1f25e7befd1d3",
    daoMemFollId: "66f3e33ef604240f9640467e",
    proposalId: "66f64bf64006bd33cd1a3871",
    voteId: "66f64aab4006bd33cd1a3870",
    userId: "673c17c66ba65b206f9025cd",
    blockchainId: "675a81adf0cb545c5134cfaa",
};

export const TEDAAS_WORKFLOWS = {
    executeProposalMint: "441",
    updateTokenHolders: "444",
    accessToken:
        "eyJhbGciOiJSUzI1NiIsInR5cCIgOiAiSldUIiwia2lkIiA6ICI3Ny1NUVdFRTNHZE5adGlsWU5IYmpsa2dVSkpaWUJWVmN1UmFZdHl5ejFjIn0.eyJleHAiOjE3Mjk2MTUwOTksImlhdCI6MTcyOTU3OTA5OSwianRpIjoiYTc2YjcwYTYtZWFjZi00ZWIzLThkMDItNDZiYmNmMDg3YjliIiwiaXNzIjoiaHR0cDovL2tleWNsb2FrLmtleWNsb2FrLnN2Yy5jbHVzdGVyLmxvY2FsOjgwODAvcmVhbG1zL21hc3RlciIsImF1ZCI6WyJQQVNDQUxfSU5URUxMSUdFTkNFIiwiWFBYLUNNUyIsIkhPTEFDUkFDWSIsImFjY291bnQiXSwic3ViIjoiN2MyYTBjYzUtNjk4OC00OTk5LThmNmQtNDgyMzYzNDgyZWVmIiwidHlwIjoiQmVhcmVyIiwiYXpwIjoiY2RmZyIsInNlc3Npb25fc3RhdGUiOiI2NTg5NTEzZS1jNTU2LTRjZDAtYjZjOC01NGE5ODJlMWU2Y2MiLCJuYW1lIjoibW9iaXVzIG1vYml1cyIsImdpdmVuX25hbWUiOiJtb2JpdXMiLCJmYW1pbHlfbmFtZSI6Im1vYml1cyIsInByZWZlcnJlZF91c2VybmFtZSI6InBhc3N3b3JkX3RlbmFudF9tb2JpdXNAbW9iaXVzZHRhYXMuYWkiLCJlbWFpbCI6InBhc3N3b3JkX3RlbmFudF9tb2JpdXNAbW9iaXVzZHRhYXMuYWkiLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiYWNyIjoiMSIsInJlYWxtX2FjY2VzcyI6eyJyb2xlcyI6WyJkZWZhdWx0LXJvbGVzLW1hc3RlciIsIm9mZmxpbmVfYWNjZXNzIiwidW1hX2F1dGhvcml6YXRpb24iXX0sInJlc291cmNlX2FjY2VzcyI6eyJQQVNDQUxfSU5URUxMSUdFTkNFIjp7InJvbGVzIjpbIlNVUEVSQURNSU4iXX0sIlhQWC1DTVMiOnsicm9sZXMiOlsiWFBYLUNNU19VU0VSIl19LCJIT0xBQ1JBQ1kiOnsicm9sZXMiOlsiSE9MQUNSQUNZX1VTRVIiXX0sImNkZmciOnsicm9sZXMiOlsiQk9MVFpNQU5OX0JPVF9VU0VSIl19LCJhY2NvdW50Ijp7InJvbGVzIjpbIm1hbmFnZS1hY2NvdW50IiwibWFuYWdlLWFjY291bnQtbGlua3MiLCJ2aWV3LXByb2ZpbGUiXX19LCJzY29wZSI6InByb2ZpbGUgZW1haWwiLCJzaWQiOiI2NTg5NTEzZS1jNTU2LTRjZDAtYjZjOC01NGE5ODJlMWU2Y2MiLCJ0ZW5hbnRJZCI6IjdjMmEwY2M1LTY5ODgtNDk5OS04ZjZkLTQ4MjM2MzQ4MmVlZiIsInJlcXVlc3RlclR5cGUiOiJURU5BTlQifQ==.iDdwPlrCU7wnb3lbfWmTDWY2SNCsoi9GM8URmLvPgYklxP8YsdJIh7X1iLk-ia9WYCSimk-DD-e33j_emc7UM1DljConpVsitTdkANpvx0w8zFdUAdYeJJnkijdNXW2ALHui6vskK14EuIccYMUHWNEOWe15_7UPqGuQrOtz37eNw-uvmqsBulWAtxLw-EOKDjhN_JO7SoQQa9TWLWi8YECEqXSIDVq0ZE30IvzPL0OmTQZ5eRA7i_jBO66IQ-LGrZSrcj6EQzzALrQC9SQNmlm8J2iJePJCrspCq8wDmLdPKSNQhej-mOtgnWukIWgB2rIT5iaFPzHMg6VEEjKHJw",
};
