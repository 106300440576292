import { TOKEN_CREATION_MODEL, TOKEN_CREATION_DRAG, TOKEN_ACTION_MODAL } from "../../components/TeDaaSV3/Constants/Constants";

export const tokenCreationSetDrag = (flag) => ({
    type: TOKEN_CREATION_DRAG,
    payload: flag,
});

export const tokenCreationOpenModel = (data, name = "", id = "", position = { x: 0, y: 0 }) => ({
    type: TOKEN_CREATION_MODEL,
    payload: {
        burn: false,
        mint: false,
        changeOwner: false,
        transactionTax: false,
        buyback: false,
        assignee: false,
        reward: false,
        newToken: false,
        tokenPrice: false,
        daoType: false,
        tokenDOcs: false,
        [data]: true,
        name: name,
        id: id,
        position: position,
    },
});

export const tokenCreationCloseModel = () => ({
    type: TOKEN_CREATION_MODEL,
    payload: {
        burn: false,
        mint: false,
        changeOwner: false,
        transactionTax: false,
        buyback: false,
        assignee: false,
        daoType: false,
        reward: false,
        tokenPrice: false,
        tokenDOcs: false,
        newToken: false,
        name: "",
        id: "",
    },
});

export const tokenActionOpenModal = (data) => ({
    type: TOKEN_ACTION_MODAL,
    payload: {
        "Utility Token": false,
        Mint: false,
        Burn: false,
        Blacklist: false,
        Transfer: false,
        "Edit Burn Rate": false,
        Stake: false,
        "Change Staking APR": false,
        Withdraw: false,
        "Withdraw Full": false,
        "Taxable Transaction": false,
        Approve: false,
        "Blacklist Addresses": false,
        "Edit Token Docs": false,
        "Revenue Share": false,
        "Pause / Unpause": false,
        Buyback: false,
        "Change Owner": false,
        "Edit Tx Tax": false,
        "Change Token Limit": false,
        "Tranfer To User": false,
        [data]: true,
        "modal": true,
    },
});
export const tokenActionCloseModal = () => ({
    type: TOKEN_ACTION_MODAL,
    payload: {
        "Utility Token": false,
        Mint: false,
        Burn: false,
        Blacklist: false,
        Transfer: false,
        "Edit Burn Rate": false,
        Stake: false,
        "Change Staking APR": false,
        Withdraw: false,
        "Withdraw Full": false,
        "Taxable Transaction": false,
        Approve: false,
        "Blacklist Addresses": false,
        "Edit Token Docs": false,
        "Revenue Share": false,
        "Pause / Unpause": false,
        Buyback: false,
        "Change Owner": false,
        "Edit Tx Tax": false,
        "Change Token Limit": false,
        "Tranfer To User": false,
        "modal": false,
    },
});
