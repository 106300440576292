import axios from "axios";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../services/notificationService";
import useDeployContract from "./useDeployContract";
import fs from "fs";
import {
    DAO_CONTRACT_ABI,
    DAO_CONTRACT_VERIFICATION,
    DAO_FACTORY_CONTRACT_ABI,
    DAO_FACTORY_MOBIUS_CONTRACT_ADDRESS,
    DAO_FACTORY_SEPOLIA_CONTRACT_ADDRESS,
    DAO_MANAGEMENT_CONTRACT_ABI,
} from "../Constants/DAOContractConstructs";
import usePostTokenData from "./usePostTokenData";
import usePostDAOData from "./usePostDAOData";
import daoSourceCodeTxtFile from "../Constants/DAO_CONTRACT_SOURCE_CODE.txt";
import { getFilteredEntities, insertEntity, triggerTedaasWorkflow, verifyContractViaEtherscan } from "../../../services/stf";
import { TEDAAS_SCHEMAS, TEDAAS_WORKFLOWS } from "../Constants/Constants";
import { updateLoader } from "../../../redux/actions/loaderAction";
import usePostProposalData from "./usePostProposalData";
import { proposalCreationConfigureVal, proposalCreationReset } from "../../../redux/actions/proposalCreationAction";
import { stringify } from "uuid";
import { daoCreationConfigureVal, daoCreationReset } from "../../../redux/actions/daoCreationAction";

export const useDAOAction = () => {
    const signer = useSelector((state) => state.auth.signer);
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const daoData = useSelector((state) => state.dc);
    const proposalData = useSelector((state) => state.pc);
    const { getConstructorParams } = useDeployContract();
    const { postNewTokenTxn, postTokenHolders, tokenHoldersUpdate, postDAOGovernanceToken } = usePostTokenData();
    const { postDAOData, postDAOMembersData } = usePostDAOData();
    const { postProposalData } = usePostProposalData();
    const dispatch = useDispatch();
    // const [contract, setContract] = useState(new ether)
    //updated
    const createContractInstance = () => {
        try {
            const contractAbi = DAO_FACTORY_CONTRACT_ABI;
            const contract = new ethers.Contract(DAO_FACTORY_SEPOLIA_CONTRACT_ADDRESS, contractAbi, signer);
            return { contract, contractAbi };
        } catch (e) {
            console.log("Error in Create Contract Instance", e);
            throw Error("\nError in Creating Contract Instance > " + e);
        }
    };
    const createDAOContractInstance = (daoAddress) => {
        try {
            const contractAbi = DAO_CONTRACT_ABI;
            // const daoManagementAddress = await contract.daoManagement();

            const contract = new ethers.Contract(daoAddress, contractAbi, signer);
            return { contract, contractAbi };
        } catch (e) {
            console.log("Error in Create Contract Instance", e);
            throw Error("\nError in Creating Contract Instance > " + e);
        }
    };
    const createDAOManagementContractInstance = async () => {
        try {
            const { contract, contractAbi } = createContractInstance();
            const daoManagementAddress = await contract.daoManagement();
            const contractAbi2 = DAO_MANAGEMENT_CONTRACT_ABI;
            const contract2 = new ethers.Contract(daoManagementAddress, contractAbi2, signer);
            return { contract: contract2, contractAbi: contractAbi2 };
        } catch (e) {
            console.log("Error in Create Contract Instance", e);
            throw Error("\nError in Creating Contract Instance > " + e);
        }
    };
    const createDAO = async () => {
        try {
            dispatch(updateLoader(true));
            // console.log("Creating Contract Instance");
            const { contract, contractAbi } = createContractInstance();
            console.log("Getting Constructor Params");
            const constructorParams = await getConstructorParams("DAO");
            console.log(constructorParams);
            await contract.daoId();
            console.log("Creating DAO", constructorParams);
            const tx = await contract.createDAO(...constructorParams);
            console.log("Waiting for transaction");
            const receipt = await tx.wait();
            console.log("TX RECEIPT", receipt);
            // const rawAddress = receipt.logs[3].topics[2];
            const len = receipt.events.length;
            const rawAddress = receipt.events[len - 1].args[0];
            const tokenAddress = receipt.logs[0].address;
            console.log("DAO Created at address:", rawAddress);
            console.log("Token Address", tokenAddress);
            // const decodeOption = ["address"];
            // const decodedData = ethers.utils.defaultAbiCoder.decode(decodeOption, rawAddress);
            // console.log("DAO Created at address:", decodedData);
            const daoAddress = rawAddress;
            const txHash = tx.hash;
            const hexString = tx.data;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            const contractVerificationParams = { ...DAO_CONTRACT_VERIFICATION };
            contractVerificationParams.contractaddress = daoAddress;

            const daoSourceCode = await fetch(daoSourceCodeTxtFile)
                .then((r) => r.text())
                .then((text) => {
                    return text;
                });
            contractVerificationParams.sourceCode = daoSourceCode;
            const resVerification = await verifyContractViaEtherscan(contractVerificationParams);
            console.log("Verification");
            console.log(resVerification);
            // const daoSourceCode = fs.readFileSync("../Constants/DAO_CONTRACT_SOURCE_CODE.txt");
            // console.log(daoSourceCode);

            await postNewTokenTxn(
                daoData.participationInGovernance.contractAddress === "" ? tokenAddress : daoData.participationInGovernance.contractAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                0,
                signerAddress,
                daoAddress,
                "Create DAO",
                "DAO",
                daoAddress,
            );
            if (daoData.participationInGovernance.contractAddress === "" && daoData.participationInGovernance.participant==="tokenHolders") {
                let totalSupply = 0;
                for (let i of constructorParams[4]) {
                    totalSupply += parseInt(i.deposit);
                }
                await postDAOGovernanceToken(
                    signerAddress,
                    daoData.participationInGovernance.tokenName,
                    daoData.participationInGovernance.tokenSymbol,
                    tokenAddress,
                    totalSupply,
                );
            }
            console.log("Going to POsting DAO DATA");
            await postDAOData(
                daoAddress,
                daoData.participationInGovernance.contractAddress === "" ? tokenAddress : daoData.participationInGovernance.contractAddress,
            );
            // await postDAOMembersData(daoAddress, signerAddress, "member");
            console.log("Done POsting DAO DATA");
            await tokenHoldersUpdate(
                constructorParams[4],
                daoData.participationInGovernance.contractAddress === "" ? tokenAddress : daoData.participationInGovernance.contractAddress,
            );
            await postDAOMembersData(constructorParams[4], daoAddress);
            // await insertEntity(arr, TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken, true);
            // await triggerTedaasWorkflow(TEDAAS_WORKFLOWS.updateTokenHolders, [{ key: "tokenHoldersArr", value: JSON.stringify(arr) }]);
            dispatch(updateLoader(false));
            dispatch(daoCreationReset());
            return true;
        } catch (e) {
            showErrorToast("DAO not deployed");
            console.log(e);
            dispatch(updateLoader(false));
            return false;
        }
    };
    const createProposal = async () => {
        try {
            // console.log("Proposal", proposalData);
            dispatch(updateLoader(true));
            const daoRes = await getFilteredEntities({ daoAddress: proposalData.daoAddress }, TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
            const daoResEntity = daoRes.entities[0];
            const tokenAddress = daoResEntity.tokenAddress;
            let { contract, contractAbi } = await createDAOManagementContractInstance();
            const constructorParams = await getConstructorParams("proposal", tokenAddress, daoResEntity.daoAddress);

            console.log("Proposal Params", constructorParams);
            // const title = "Increase Token Supply";
            // const description = "This proposal aims to increase the token supply mint to 0x3C44CdDdB6a900fa2b585dd299e03d12FA4293BC";
            // const startTime = Math.floor(Date.now() / 1000);
            // const duration = 604800; // Duration in seconds (1 week)
            // const actionId = 0;
            // const MintActions = [["0x05367ed7B7488139A03458F96a491f7e06b7D65d",0,"0xe742806a0000000000000000000000004b20993bc481177ec7e8f571cecae8a9e22c02db0000000000000000000000000000000000000000000000000000000000000384"]]
            // const tx = await contract.createProposal(title, description, startTime, duration,actionId, MintActions);
            const tx = await contract.createProposal(...constructorParams);
            console.log("Creating Proposal", tx);
            // receipt = await wait(tx);
            const receipt = await tx.wait();
            console.log("TX RECEIPT", receipt);
            const len = receipt.events.length;
            const rawAddress = receipt.events[len - 1].args["proposal"];
            console.log("Raw Address:", rawAddress);
            // const decodeOption = ["address"];
            // const decodedData = ethers.utils.defaultAbiCoder.decode(decodeOption, rawAddress);
            // const proposalAddress = decodedData[0];
            const proposalAddress = rawAddress;
            console.log("Proposal Address:", proposalAddress);
            const res = await postProposalData(proposalAddress, tokenAddress);
            console.log("Proposal Posted");
            const decimalValue = parseInt(tx.gasPrice, 16);
            console.log("Decimal Value:", decimalValue);
            const GasgweiValue = decimalValue / 1e9;
            console.log("GasgweiValue:", GasgweiValue);
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                tx.hash,
                GasgweiValue.toFixed(2),
                0,
                signerAddress,
                proposalData.daoAddress,
                "Create Proposal",
                "Proposal",
                proposalAddress,
            );
            dispatch(proposalCreationReset());
            return res;
        } catch (e) {
            showErrorToast("Proposal not created");
            console.log(e);
            dispatch(updateLoader(false));
            throw Error(e);
        }
    };

    const checkDAOMember = async (daoAddress, members) => {
        try {
            let { contract, contractAbi } = await createDAOContractInstance(daoAddress);
            const verified = [];
            for (let i of members) {
                const json = {
                    ...i,
                    userAddress: i.userAddress,
                    isMember: false,
                };
                const isMember = await contract.isDAOMember(i.userAddress);
                json.isMember = isMember;
                verified.push(json);
            }
            return verified;
        } catch (e) {
            showErrorToast("DAO Member can't be verified");
            console.log(e);
            throw Error(e);
        }
    };

    const checkDAOTreasuryTokens = async (daoAddress) => {
        try {
            let { contract, contractAbi } = await createDAOContractInstance(daoAddress);
            const tokens = contract.getTotalTreasuryTokens();
            console.log("CHECK DAO TREASURY TOKENS", tokens);
            return true;
        } catch (e) {
            showErrorToast("DAO Treasury can't be checked");
            console.log(e);
            throw Error(e);
        }
    };

    const checkDAOTreasuryTokenDeposits = async (daoAddress, tokenAddress) => {
        try {
            let { contract, contractAbi } = await createDAOContractInstance(daoAddress);
            const tokens = contract.totalTokenDeposits(tokenAddress);
            console.log("CHECK DAO TREASURY TOKENS", tokens);
            return true;
        } catch (e) {
            showErrorToast("DAO Treasury can't be checked");
            console.log(e);
            throw Error(e);
        }
    };

    const checkDAOTreasury = async (daoAddress, members) => {
        try {
            let { contract, contractAbi } = await createDAOContractInstance(daoAddress);
            const tokens =await contract.getTotalTreasuryTokens();
            console.log("CHECK DAO TREASURY TOKENS", tokens);
            const treasury = [];
            for(let i of tokens){
                const token = {
                    tokenAddress: "",
                    balance: 0
                }
                token.tokenAddress = i[0];
                const balance = i[1];
                token.balance = parseInt(ethers.utils.formatUnits(balance, 18));
                treasury.push(token);
            }
            console.log("DAO Treasury", treasury);
            return treasury;
        } catch (e) {
            showErrorToast("DAO Treasury can't be checked");
            console.log(e);
            throw Error(e);
        }
    };
    return { createDAO, createProposal, checkDAOMember, checkDAOTreasury, checkDAOTreasuryTokens, checkDAOTreasuryTokenDeposits };
};
