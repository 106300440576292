import React, { useEffect, useState } from "react";
import css from "./DAODetailsProposals.module.scss";
import { Button, Input } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { capitalizeFirstLetterOfString, copyToClipboard, shortenAddress } from "../../../../Constants/reusableFunctions";
import dummyLogo from "../../../../TeDaaS-Assets/proposalCreate/dummyLogo.svg";
import { useNavigate, useParams } from "react-router-dom";
import { getFilteredEntities } from "../../../../../../services/stf";
import { TEDAAS_SCHEMAS } from "../../../../Constants/Constants";
import { updateLoader } from "../../../../../../redux/actions/loaderAction";
import ProposalEmptyCard from "../../../UI/ProposalEmptyCard/ProposalEmptyCard";
import { proposalCreationConfigureVal } from "../../../../../../redux/actions/proposalCreationAction";

function DAODetailsProposals({ proposals, token, members, dao }) {
    const [status, setStatus] = useState("all");
    const [filter, setFilter] = useState("");
    const navigateTo = useNavigate();
    const { daoAddress } = useParams();
    const checkStatus = (start, end, status) => {
        if (status == "Succeded" || status == "Defeated" || status === "Executed" || status === "Expired") {
            return status;
        }
        const currentTime = new Date().getTime();
        // console.log(start, end);
        if (parseInt(start) > currentTime) {
            return "Pending";
        } else if (parseInt(start) <= currentTime && currentTime <= parseInt(end)) {
            return "Active";
        } else {
            return "Expired";
        }
    };
    const dispatch = useDispatch();
    useEffect(() => {}, [proposals, token, members, dao, daoAddress]);
    return (
        <div className={css.container}>
            {proposals.length > 0 && (
                <div className={css.body}>
                    <div className={css.header}>
                        <span className={css.headerLeft}>Proposals</span>
                        <div className={css.headerRight}>
                            <Button type="default" className={css.button}>
                                Delegate
                            </Button>
                            <Button
                                type="primary"
                                className={css.button}
                                onClick={() => {
                                    dispatch(proposalCreationConfigureVal("daoAddress", daoAddress));
                                    navigateTo("/tedaasV3/createProposal/proposalMeta");
                                }}
                            >
                                {"+ New Proposal"}
                            </Button>
                        </div>
                    </div>
                    <div className={css.navbar}>
                        <div className={css.navbarLeft}>
                            <span
                                className={status === "all" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "all") {
                                        setStatus("all");
                                    }
                                }}
                            >
                                All
                            </span>
                            <span
                                className={status === "Pending" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "Pending") {
                                        setStatus("Pending");
                                    }
                                }}
                            >
                                Pending
                            </span>
                            <span
                                className={status === "Active" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "Active") {
                                        setStatus("Active");
                                    }
                                }}
                            >
                                Active
                            </span>
                            <span
                                className={status === "Succeeded" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "Succeeded") {
                                        setStatus("Succeeded");
                                    }
                                }}
                            >
                                Succeeded
                            </span>
                            <span
                                className={status === "Executed" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "Executed") {
                                        setStatus("Executed");
                                    }
                                }}
                            >
                                Executed
                            </span>
                            <span
                                className={status === "Defeated" ? css.activeNav : css.nav}
                                onClick={() => {
                                    if (status !== "Defeated") {
                                        setStatus("Defeated");
                                    }
                                }}
                            >
                                Defeated
                            </span>
                        </div>
                        <div className={css.inputContainer}>
                            <input
                                className={css.input}
                                type="text"
                                onChange={(e) => {
                                    setFilter(e.target.value);
                                }}
                                value={filter}
                                placeholder="Type to Search"
                            />
                            <div className={css.iconContainer}>
                                <SearchOutlined className={css.icon} />
                            </div>
                        </div>
                    </div>
                    <div className={css.content}>
                        {Array.isArray(proposals) &&
                            proposals.map((proposal, i) => {
                                const propStatus = checkStatus(proposal.votingStartTime, proposal.votingEndTime, proposal.status);
                                if (status !== "all") {
                                    if (status !== propStatus) {
                                        return;
                                    }
                                }
                                if (filter !== "") {
                                    if (
                                        proposal.status.indexOf(filter) > -1 ||
                                        proposal.title.indexOf(filter) > -1 ||
                                        proposal.description.indexOf(filter) > -1 ||
                                        proposal.proposerAddress.indexOf(filter) > -1 ||
                                        proposal.proposalAddress.indexOf(filter) > -1 ||
                                        proposal.votingType.indexOf(filter) > -1
                                    ) {

                                    }else {
                                        return;
                                    }
                                }
                                return (
                                    <div
                                        key={i}
                                        className={css.proposal}
                                        onClick={() => {
                                            navigateTo("/tedaasV3/proposal/" + proposal.proposalAddress);
                                        }}
                                    >
                                        <div className={css.proposalRow1}>
                                            <span className={`${css.status} ${css[propStatus]}`}>{capitalizeFirstLetterOfString(propStatus)}</span>
                                            <div
                                                className={css.proposalBy}
                                                onClick={() => {
                                                    copyToClipboard(proposal.proposerAddress);
                                                }}
                                            >
                                                <span className={css.proposalByText}>By</span>
                                                <span className={css.proposalByLink}>{shortenAddress(proposal.proposerAddress)}</span>
                                                <img className={css.icon} src={dummyLogo} alt="User" />
                                            </div>
                                        </div>
                                        <div className={css.proposalRow2}>
                                            <span className={css.proposalTitle}>{proposal.title}</span>
                                            <span className={css.proposalDesc}>{proposal.description}</span>
                                        </div>
                                    </div>
                                );
                            })}
                    </div>
                </div>
            )}
            {proposals.length === 0 && (
                <div className={css.body}>
                    <ProposalEmptyCard />
                </div>
            )}
        </div>
    );
}

export default DAODetailsProposals;
