import React, { useEffect, useState } from "react";
import css from "./DefineMembership.module.scss";
import DAOStudioStepDescription from "../../../Components/UI/DAOStudioStepDescription/DAOStudioStepDescription";
import { useDispatch, useSelector } from "react-redux";
import { DAO_CREATION_REDUCER, SELECT_BLOCKCHAIN_OPTIONS } from "../../../Constants/Constants";
import DAOStudioSelectBlockchain from "../../../Components/UI/DAOStudioSelectBlockchain/DAOStudioSelectBlockchain";
import { Button, Checkbox, Input } from "antd";
import { daoCreationConfigureVal } from "../../../../../redux/actions/daoCreationAction";
import { Link, useNavigate } from "react-router-dom";
import uploadImg from "../../../TeDaaS-Assets/DaoCreate/upload.svg";
import generateAIImg from "../../../TeDaaS-Assets/DaoCreate/generateAI.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import copyImg from "../../../TeDaaS-Assets/DaoCreate/copy.svg";
import plusImg from "../../../TeDaaS-Assets/DaoCreate/plus.svg";
import minusImg from "../../../TeDaaS-Assets/DaoCreate/minus.svg";
import { copyToClipboard, handleImageDrop } from "../../../Constants/reusableFunctions";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./textAreaCss.css";
import { showSuccessToast, showWarningToast } from "../../../../../services/notificationService";
import { validateDaoCreationForm, validateDaoCreationFormPreviosSteps } from "../../../Constants/validateDaoCreationForm";

function DefineMembership() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.dc);
    const governanceParticipation = fetchedData[DAO_CREATION_REDUCER.PARTICIPATION_IN_GOVERNANCE];
    const daoType = fetchedData[DAO_CREATION_REDUCER.DAO_TYPE];
    const [data, setData] = useState(governanceParticipation);
    const handleNext = () => {
        const { isValid, validatedData, next } = validateDaoCreationForm("defineMembership", data);
        if (isValid === true) {
            dispatch(daoCreationConfigureVal(DAO_CREATION_REDUCER.PARTICIPATION_IN_GOVERNANCE, validatedData));
            navigateTo(next);
        }
    };
    const handleChange = (key, value) => {
        setData({ ...data, [key]: value });
    };
    const setAddressValue = (distribution, token) => {
        setData({
            ...data,
            distribution: distribution,
            totalTokens: token,
        });
    };
    const handleAddressChange = (key, value, index) => {
        let arr = data.distribution;
        if (key === "address") {
            arr[index].address = value;
            setAddressValue(arr, data.totalTokens);
        } else {
            var diff = value - arr[index].tokens;
            arr[index].tokens = value;
            setAddressValue(arr, data.totalTokens + diff);
        }
    };
    const handleMultisigAddressChange = (value, index) => {
        let arr = data.multisigMembersAddress;
        arr[index] = value;
        handleChange("multisigMembersAddress", arr);
    };
    const handleMultisigAddressRemove = (index) => {
        try {
            let arr = data.multisigMembersAddress.filter((multisigAddress, i) => i != index);
            handleChange("multisigMembersAddress", arr);
        } catch (e) {
            console.log(e);
        }
    };
    const handleAddressRemove = (index) => {
        try {
            let arr = data.distribution.filter((distributionAddress, i) => i != index);
            let decreaseToken = data.distribution[index].tokens;
            setAddressValue(arr, data.totalTokens - decreaseToken);
        } catch (e) {
            console.log(e);
        }
    };
    const handleAddressAdd = () => {
        let newAddress = {
            address: "",
            tokens: 1,
        };
        setAddressValue([...data.distribution, newAddress], data.totalTokens + 1);
    };
    useEffect(() => {
        const { isValid, next } = validateDaoCreationFormPreviosSteps("defineMembership", fetchedData);
        if (isValid) {
            dispatch(daoCreationConfigureVal("progress", 75));
        } else {
            navigateTo(next);
        }
    }, []);
    return (
        <div className={css.container}>
            <DAOStudioStepDescription
                title="Define membership"
                description="Decide the type of voting your DAO uses. You can change these settings with a vote."
                link="https://xpx.aidtaas.com/tedaasV3/dashboard"
            />
            <div className={css.body}>
                <div className={css.flexColumn}>
                    <span className={css.label}>Who can participate in governance?</span>
                    <div
                        className={`${css.tokenHoldersCard} ${data.participant === "tokenHolders" ? css.activeTokenHoldersCard : css.inactiveTokenHoldersCard}`}
                        onClick={() => {
                            if (data.participant !== "tokenHolders") {
                                handleChange("participant", "tokenHolders");
                            }
                        }}
                    >
                        <span className={css.tokenHolderName}>Token Holders</span>
                        <span className={css.tokenHolderDescription}>
                            Tokens act as voting chips. The more tokens you hold, the more weight your vote has. 1 token equals 1 vote.
                        </span>
                    </div>
                    <div
                        className={`${css.tokenHoldersCard} ${data.participant === "multisigMembers" ? css.activeTokenHoldersCard : css.inactiveTokenHoldersCard}`}
                        onClick={() => {
                            if (data.participant !== "multisigMembers") {
                                handleChange("participant", "multisigMembers");
                            }
                        }}
                    >
                        <span className={css.tokenHolderName}>Multisig Members</span>
                        <span className={css.tokenHolderDescription}>Only multisig members can vote. 1 wallet address equals 1 approval.</span>
                    </div>
                </div>
                {data.participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.label}>Does your community already have an ERC-20 token to govern your DAO?</span>
                        <div className={css.gridRow2}>
                            <div
                                className={`${css.checkboxContainer} ${data.hasERC20Token === false ? css.activeCheckbox : css.inactiveCheckbox}`}
                                onClick={() => {
                                    if (data.hasERC20Token !== false) {
                                        handleChange("hasERC20Token", false);
                                    }
                                }}
                            >
                                <span>No</span>
                                <Checkbox className={css.checkbox} checked={data.hasERC20Token === false}></Checkbox>
                            </div>
                            <div
                                className={`${css.checkboxContainer} ${data.hasERC20Token === true ? css.activeCheckbox : css.inactiveCheckbox}`}
                                onClick={() => {
                                    if (data.hasERC20Token !== true) {
                                        handleChange("hasERC20Token", true);
                                    }
                                }}
                            >
                                <span>Yes</span>
                                <Checkbox className={css.checkbox} checked={data.hasERC20Token === true}></Checkbox>
                            </div>
                        </div>
                    </div>
                )}
                {data.hasERC20Token === false && data.participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.title}>Mint your token</span>
                        <div className={css.mintTokenInline}>
                            <span className={css.label}>
                                Define the token details and distribute tokens to a core team and DAO treasury. For more on token minting best
                                practices,
                            </span>
                            <span
                                className={css.link}
                                onClick={() => {
                                    window.open("https://xpx.aidtaas.com/tedaasV3/dashboard", "_blank");
                                }}
                            >
                                Learn More
                            </span>
                        </div>
                    </div>
                )}
                {data.hasERC20Token === false && data.participant === "tokenHolders" && (
                    <div className={css.nameSymbolCard}>
                        <div className={css.cardTop}>
                            <span className={css.label}>Name</span>
                        </div>
                        <div className={css.nameContainer}>
                            <input
                                value={data.tokenName}
                                onChange={(e) => {
                                    handleChange("tokenName", e.target.value);
                                }}
                                type="text"
                                maxLength={128}
                                className={css.input}
                            />
                            <span className={css.length}>{data.tokenName.length}/128</span>
                        </div>
                        <span className={css.note}>The full name of the token. Example: Uniswap</span>
                    </div>
                )}
                {data.hasERC20Token === false && data.participant === "tokenHolders" && (
                    <div className={css.nameSymbolCard}>
                        <div className={css.cardTop}>
                            <span className={css.label}>Symbol</span>
                        </div>
                        <div className={css.nameContainer}>
                            <input
                                value={data.tokenSymbol}
                                onChange={(e) => {
                                    handleChange("tokenSymbol", e.target.value);
                                }}
                                type="text"
                                maxLength={128}
                                className={css.input}
                            />
                            <span className={css.length}>{data.tokenSymbol.length}/128</span>
                        </div>
                        <span className={css.note}>The abbreviation of the token. Example: UNI</span>
                    </div>
                )}
                {data.hasERC20Token === false && data.participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.label}>Distribute Tokens</span>
                        <div className={css.flexColumn2}>
                            <div className={css.distributeTokenInline}>
                                <span>Add the wallets you'd like to distribute tokens to. If you need help distributing tokens,</span>
                                <span
                                    className={css.link}
                                    onClick={() => {
                                        window.open("https://xpx.aidtaas.com/tedaasV3/dashboard", "_blank");
                                    }}
                                >
                                    read our guide
                                </span>
                            </div>
                            <div className={css.gridRowAddress}>
                                <span className={css.addressLabel}>Address</span>
                                <span className={css.addressLabel}>Tokens</span>
                                <span className={css.addressLabel}>Allocation</span>
                            </div>
                            {data.distribution.map((distributionAddress, i) => {
                                return (
                                    <div className={css.gridRowAddress}>
                                        <div className={css.textCopy}>
                                            <input
                                                className={css.input}
                                                type="text"
                                                placeholder="0xB178...4b33"
                                                value={distributionAddress.address}
                                                onChange={(e) => {
                                                    handleAddressChange("address", e.target.value, i);
                                                }}
                                            />
                                            <img
                                                className={css.icon}
                                                src={copyImg}
                                                onClick={() => {
                                                    copyToClipboard(distributionAddress);
                                                }}
                                                alt="Copy to Clipboard"
                                            />
                                        </div>
                                        <div className={css.increaseDecreaseInput}>
                                            <img
                                                className={css.icon}
                                                src={minusImg}
                                                alt="Decrease"
                                                onClick={() => {
                                                    if (distributionAddress.tokens > 1) {
                                                        handleAddressChange("tokens", distributionAddress.tokens - 1, i);
                                                    }
                                                }}
                                            />
                                            <input
                                                className={css.input}
                                                type="text"
                                                value={distributionAddress.tokens}
                                                onChange={(e) => {
                                                    if (/^[0-9]+$/.test(e.target.value)) {
                                                        handleAddressChange("tokens", parseInt(e.target.value), i);
                                                    }
                                                }}
                                            />
                                            <img
                                                className={css.icon}
                                                src={plusImg}
                                                alt="Increase"
                                                onClick={() => {
                                                    handleAddressChange("tokens", distributionAddress.tokens + 1, i);
                                                }}
                                            />
                                        </div>
                                        <span className={css.allocation}>
                                            {`${((distributionAddress.tokens / data.totalTokens) * 100).toFixed(2)}%`}
                                        </span>
                                        <div
                                            className={css.menuContainer}
                                            onClick={() => {
                                                handleAddressRemove(i);
                                            }}
                                        >
                                            <img className={css.icon} src={menuImg} alt="Menu" />
                                        </div>
                                    </div>
                                );
                            })}
                            <div className={css.gridRowAddress}>
                                <span className={css.addressLabel}>{`${data.distribution.length} Address`}</span>
                                <span className={css.addressLabel}>{`${data.totalTokens}`}</span>
                                <span className={css.addressLabel}>{`${data.totalTokens > 0 ? 100 : 0}%`}</span>
                            </div>
                        </div>
                    </div>
                )}
                {data.hasERC20Token === false && data.participant === "tokenHolders" && (
                    <Button type="default" className={css.addAddressButton} onClick={handleAddressAdd}>
                        Add Wallet
                    </Button>
                )}

                {data.hasERC20Token === true && data.participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.title}>Import your existing token</span>
                        <div className={css.mintTokenInline}>
                            <span className={css.label}>
                                Import the existing ERC-20 token that your community uses for governance. We recommend to read about why this is
                                important in
                            </span>
                            <span
                                className={css.link}
                                onClick={() => {
                                    window.open("https://xpx.aidtaas.com/tedaasV3/dashboard", "_blank");
                                }}
                            >
                                Our Guide
                            </span>
                        </div>
                    </div>
                )}
                {data.hasERC20Token === true && data.participant === "tokenHolders" && (
                    <div className={css.nameSymbolCard}>
                        <div className={css.cardTop}>
                            <span className={css.label}>Contract Address</span>
                        </div>
                        <div className={css.nameContainer}>
                            <input
                                value={data.contractAddress}
                                onChange={(e) => {
                                    handleChange("contractAddress", e.target.value);
                                }}
                                type="text"
                                maxLength={128}
                                className={css.input}
                            />
                            <span className={css.length}>{data.contractAddress.length}/128</span>
                        </div>
                        <span className={css.note}>Enter the contract address. We recommend pasting it directly from a block explorer.</span>
                    </div>
                )}

                {data.participant === "multisigMembers" && (
                    <div className={css.flexColumn}>
                        <span className={css.title}>Multisig members</span>
                    </div>
                )}
                {data.participant === "multisigMembers" && (
                    <div className={css.flexColumn2}>
                        <span className={css.label}>Address</span>
                        {data.multisigMembersAddress.map((multisigAddress, i) => {
                            return (
                                <div className={css.multisigAddress}>
                                    <div className={css.textCopy}>
                                        <input
                                            className={css.input}
                                            type="text"
                                            placeholder="0xB178...4b33"
                                            value={multisigAddress}
                                            onChange={(e) => {
                                                handleMultisigAddressChange(e.target.value, i);
                                            }}
                                        />
                                        <img
                                            className={css.icon}
                                            src={copyImg}
                                            onClick={() => {
                                                copyToClipboard(multisigAddress);
                                            }}
                                            alt="Copy to Clipboard"
                                        />
                                    </div>
                                    <div
                                        className={css.menuContainer}
                                        onClick={() => {
                                            handleMultisigAddressRemove(i);
                                        }}
                                    >
                                        <img className={css.icon} src={menuImg} alt="Menu" />
                                    </div>
                                </div>
                            );
                        })}
                        <div className={css.multisigAddress}>
                            <div className={css.totalAddress}>
                                <span className={css.label}>Total Address</span>
                                <span className={css.label}>{data.multisigMembersAddress.length}</span>
                            </div>
                        </div>
                        <span className={css.description}>
                            There is no limit on the number of addresses on your multisig. Addresses can create proposals, create and approve
                            transactions, and suggest changes to the DAO settings after creation.
                        </span>
                    </div>
                )}
                {data.participant === "multisigMembers" && (
                    <Button
                        type="default"
                        className={css.addAddressButton}
                        onClick={() => {
                            handleChange("multisigMembersAddress", [...data.multisigMembersAddress, ""]);
                        }}
                    >
                        Add Address
                    </Button>
                )}
            </div>
            <div className={css.buttons}>
                <Link to={`/tedaasV3/buildYourDAO/describeYourDAO`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
            </div>
        </div>
    );
}

export default DefineMembership;
