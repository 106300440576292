import React, { useEffect, useState } from "react";
import css from "./SelectGovernanceSettings.module.scss";
import DAOStudioStepDescription from "../../../Components/UI/DAOStudioStepDescription/DAOStudioStepDescription";
import { useDispatch, useSelector } from "react-redux";
import { DAO_CREATION_REDUCER, SELECT_BLOCKCHAIN_OPTIONS } from "../../../Constants/Constants";
import DAOStudioSelectBlockchain from "../../../Components/UI/DAOStudioSelectBlockchain/DAOStudioSelectBlockchain";
import { Button, Checkbox, Input, Progress, Slider, Tag } from "antd";
import { daoCreationConfigureVal } from "../../../../../redux/actions/daoCreationAction";
import { Link, useNavigate } from "react-router-dom";
import uploadImg from "../../../TeDaaS-Assets/DaoCreate/upload.svg";
import generateAIImg from "../../../TeDaaS-Assets/DaoCreate/generateAI.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import copyImg from "../../../TeDaaS-Assets/DaoCreate/copy.svg";
import plusImg from "../../../TeDaaS-Assets/DaoCreate/plus.svg";
import minusImg from "../../../TeDaaS-Assets/DaoCreate/minus.svg";
import { copyToClipboard, formatInputToIncludeGTandPercnt, handleImageDrop } from "../../../Constants/reusableFunctions";
import { DeleteFilled } from "@ant-design/icons";
import TextArea from "antd/es/input/TextArea";
import "./textAreaCss.css";
import { showSuccessToast, showWarningToast } from "../../../../../services/notificationService";
import { validateDaoCreationForm, validateDaoCreationFormPreviosSteps } from "../../../Constants/validateDaoCreationForm";

function SelectGovernanceSettings() {
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const fetchedData = useSelector((state) => state.dc);
    const participationInGovernance = fetchedData.participationInGovernance;
    const participant = participationInGovernance.participant;
    const governanceSettings = fetchedData[DAO_CREATION_REDUCER.GOVERNANCE_SETTING];
    const governanceParticipation = fetchedData[DAO_CREATION_REDUCER.PARTICIPATION_IN_GOVERNANCE];
    const [data, setData] = useState(governanceSettings);
    const handleNext = () => {
        const { isValid, validatedData, next } = validateDaoCreationForm("governanceSettings", data);
        if (isValid === true) {
            dispatch(daoCreationConfigureVal(DAO_CREATION_REDUCER.GOVERNANCE_SETTING, validatedData));
            navigateTo(next);
        }
    };
    const handleChange = (key, value) => {
        setData({ ...data, [key]: value });
    };
    const maxAddresses =
        governanceParticipation.participant === "tokenHolders"
            ? governanceParticipation.hasERC20Token === true
                ? 1
                : governanceParticipation.distribution.length
            : governanceParticipation.multisigMembersAddress.length;
    console.log(maxAddresses);

    useEffect(() => {
        const { isValid, next } = validateDaoCreationFormPreviosSteps("governanceSettings", fetchedData);
        if (isValid) {
            dispatch(daoCreationConfigureVal("progress", 100));
        } else {
            navigateTo(next);
        }
    }, []);
    return (
        <div className={css.container}>
            <DAOStudioStepDescription
                title="Select governance settings"
                description="These are the rules that define how decisions are made in your DAO. How many people have to participate? How much support is needed? How long are proposals open for voting?"
                link="https://xpx.aidtaas.com/tedaasV3/dashboard"
            />
            <div className={css.body}>
                {participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.label}>Support Threshold</span>
                        <div className={css.flexColumn2}>
                            <div className={css.gridRowSupport}>
                                <div className={css.increaseDecreaseInput}>
                                    <img
                                        className={css.icon}
                                        src={minusImg}
                                        alt="Decrease"
                                        onClick={() => {
                                            if (data.supportThreeshold > 1) {
                                                handleChange("supportThreeshold", data.supportThreeshold - 1);
                                            }
                                        }}
                                    />
                                    <input className={css.input} type="text" readOnly value={`<${data.supportThreeshold}%`} />
                                    <img
                                        className={css.icon}
                                        src={plusImg}
                                        alt="Increase"
                                        onClick={() => {
                                            if (data.supportThreeshold < 100) {
                                                handleChange("supportThreeshold", data.supportThreeshold + 1);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={css.supportThreeshold}>
                                    <Button
                                        className={css.supportThreesholdButtons}
                                        onClick={() => {
                                            if (data.supportThreeshold < 100) {
                                                handleChange("supportThreeshold", data.supportThreeshold + 1);
                                            }
                                        }}
                                    >
                                        Yes
                                    </Button>
                                    <Progress className={css.supportThreesholdProgress} percent={53} showInfo={false} />
                                    <Button
                                        className={css.supportThreesholdButtons}
                                        onClick={() => {
                                            if (data.supportThreeshold > 1) {
                                                handleChange("supportThreeshold", data.supportThreeshold - 1);
                                            }
                                        }}
                                    >
                                        No
                                    </Button>
                                </div>
                            </div>
                            <span className={css.description}>
                                The percentage of tokens that vote "Yes" in support of a proposal, out of all tokens that have voted, must be greater
                                than this value for the proposal to pass.
                            </span>
                            {data.supportThreeshold > 50 && <span className={css.greenNote}>Proposal will be approved by majority</span>}
                        </div>
                    </div>
                )}
                {participant === "tokenHolders" && (
                    <div className={css.flexColumn}>
                        <span className={css.label}>Minimum Participation</span>
                        <div className={css.flexColumn2}>
                            <div className={css.gridRowSupport}>
                                <div className={css.increaseDecreaseInput}>
                                    <img
                                        className={css.icon}
                                        src={minusImg}
                                        alt="Decrease"
                                        onClick={() => {
                                            if (data.minimumParticipation > 1) {
                                                handleChange("minimumParticipation", data.minimumParticipation - 1);
                                            }
                                        }}
                                    />
                                    <input className={css.input} type="text" readOnly value={`<${data.minimumParticipation}%`} />
                                    <img
                                        className={css.icon}
                                        src={plusImg}
                                        alt="Increase"
                                        onClick={() => {
                                            if (data.minimumParticipation > 1) {
                                                handleChange("minimumParticipation", data.minimumParticipation - 1);
                                            }
                                        }}
                                    />
                                </div>
                            </div>
                            <span className={css.description}>
                                The percentage of tokens that participate in a proposal, out of the total coin supply, must be greater than or equal
                                to this value for the proposal to pass.
                            </span>
                        </div>
                    </div>
                )}
                <div className={css.flexColumn}>
                    <span className={css.label}>Minimum Duration</span>
                    <div className={css.flexColumn2}>
                        <span className={css.description}>
                            The shortest period of time a proposal is open for voting. Proposals can be created with a longer duration, but not
                            shorter.
                        </span>
                        <div className={css.gridRow3}>
                            <span className={css.label12}>Minutes</span>
                            <span className={css.label12}>Hours</span>
                            <span className={css.label12}>Days</span>
                        </div>
                        <div className={css.gridRow3}>
                            <div className={css.increaseDecreaseInput}>
                                <img
                                    className={css.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (data.minimumDuration.minutes > 0) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, minutes: data.minimumDuration.minutes - 1 });
                                        }
                                    }}
                                />
                                <input
                                    className={css.input}
                                    type="text"
                                    value={data.minimumDuration.minutes}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 60) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, minutes: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (data.minimumDuration.minutes < 59) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, minutes: data.minimumDuration.minutes + 1 });
                                        }
                                    }}
                                />
                            </div>
                            <div className={css.increaseDecreaseInput}>
                                <img
                                    className={css.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (data.minimumDuration.hours > 0) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, hours: data.minimumDuration.hours - 1 });
                                        }
                                    }}
                                />
                                <input
                                    className={css.input}
                                    type="text"
                                    value={data.minimumDuration.hours}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 24) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, hours: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (data.minimumDuration.hours < 23) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, hours: data.minimumDuration.hours + 1 });
                                        }
                                    }}
                                />
                            </div>
                            <div className={css.increaseDecreaseInput}>
                                <img
                                    className={css.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (data.minimumDuration.days > 1) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, days: data.minimumDuration.days - 1 });
                                        }
                                    }}
                                />
                                <input
                                    className={css.input}
                                    type="text"
                                    value={data.minimumDuration.days}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 60) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, days: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (data.minimumDuration.days < 30) {
                                            handleChange("minimumDuration", { ...data.minimumDuration, days: data.minimumDuration.days + 1 });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <span className={css.description}>
                            Set this to a duration that is long enough for your members to have sufficient time to vote. It’s recommended to set this
                            to at least 1 day.
                        </span>
                    </div>
                </div>
                <div className={css.flexColumn2}>
                    <span className={css.label}>Early execution</span>
                    <span className={css.description}>
                        Allows a proposal to be executed before the voting period ends if the support threshold is surpassed, the minimum
                        participation is met, and the outcome cannot be changed by more voters participating.
                    </span>
                    <div className={css.gridRow2}>
                        <div
                            className={`${css.checkboxContainer} ${data.earlyExecution === false ? css.activeCheckbox : css.inactiveCheckbox}`}
                            onClick={() => {
                                if (data.earlyExecution !== false) {
                                    handleChange("earlyExecution", false);
                                }
                            }}
                        >
                            <span>No</span>
                            <Checkbox className={css.checkbox} checked={data.earlyExecution === false}></Checkbox>
                        </div>
                        <div
                            className={`${css.checkboxContainer} ${data.earlyExecution === true ? css.activeCheckbox : css.inactiveCheckbox}`}
                            onClick={() => {
                                if (data.earlyExecution !== true) {
                                    handleChange("earlyExecution", true);
                                }
                            }}
                        >
                            <span>Yes</span>
                            <Checkbox className={css.checkbox} checked={data.earlyExecution === true}></Checkbox>
                        </div>
                    </div>
                </div>
                <div className={css.flexColumn2}>
                    <span className={css.label}>Vote change</span>
                    <span className={css.description}>
                        Allows voters to change their vote during the voting period. This setting can’t be enabled if early execution is enabled.
                    </span>
                    <div className={css.gridRow2}>
                        <div
                            className={`${css.checkboxContainer} ${data.voteChange === false ? css.activeCheckbox : css.inactiveCheckbox}`}
                            onClick={() => {
                                if (data.voteChange !== false) {
                                    handleChange("voteChange", false);
                                }
                            }}
                        >
                            <span>No</span>
                            <Checkbox className={css.checkbox} checked={data.voteChange === false}></Checkbox>
                        </div>
                        <div
                            className={`${css.checkboxContainer} ${data.voteChange === true ? css.activeCheckbox : css.inactiveCheckbox}`}
                            onClick={() => {
                                if (data.voteChange !== true) {
                                    handleChange("voteChange", true);
                                }
                            }}
                        >
                            <span>Yes</span>
                            <Checkbox className={css.checkbox} checked={data.voteChange === true}></Checkbox>
                        </div>
                    </div>
                </div>
                <div className={css.flexColumn2}>
                    <span className={css.label}>Proposal Creation</span>
                    <span className={css.description}>Specify who is permitted to create proposals and what the minimum requirement is.</span>
                    <div className={css.flexColumn}>
                        <div
                            className={`${css.tokenHoldersCard} ${data.proposalCreation === "members" ? css.activeTokenHoldersCard : css.inactiveTokenHoldersCard}`}
                            onClick={() => {
                                if (data.proposalCreation !== "members") {
                                    handleChange("proposalCreation", "members");
                                }
                            }}
                        >
                            <div className={css.flexColumn2}>
                                <span className={css.tokenHolderName}>Members</span>
                                <span className={css.tokenHolderDescription}>
                                    Only token holders with at least the minimum required balance or delegates minimum required voting power can
                                    create proposals.
                                </span>
                            </div>
                            <Checkbox className={css.checkbox} checked={data?.proposalCreation === "members"}></Checkbox>
                        </div>
                        <div
                            className={`${css.tokenHoldersCard} ${data.proposalCreation === "anyWallet" ? css.activeTokenHoldersCard : css.inactiveTokenHoldersCard}`}
                            onClick={() => {
                                if (data.proposalCreation !== "anyWallet") {
                                    handleChange("proposalCreation", "anyWallet");
                                }
                            }}
                        >
                            <div className={css.flexColumn2}>
                                <span className={css.tokenHolderName}>Any Wallet</span>
                                <span className={css.tokenHolderDescription}>Any connected wallet can create proposals.</span>
                            </div>
                            <Checkbox className={css.checkbox} checked={data?.proposalCreation === "anyWallet"}></Checkbox>
                        </div>
                    </div>
                </div>
                {participant === "tokenHolders" && (
                    <div className={css.flexColumn2}>
                        <span className={css.label}>Minimum Requirement</span>
                        <div className={css.gridRowSupport}>
                            <div className={css.increaseDecreaseInput}>
                                <img
                                    className={css.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (data.minimumRequirement > 1) {
                                            handleChange("minimumRequirement", data.minimumRequirement - 1);
                                        }
                                    }}
                                />
                                <input className={css.input} type="text" value={`<${data.minimumRequirement}%`} readOnly />
                                <img
                                    className={css.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (data.minimumRequirement < 100) {
                                            handleChange("minimumRequirement", data.minimumRequirement + 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                )}
                {participant === "multisigMembers" && (
                    <div className={css.flexColumn}>
                        <span className={css.label}>Minimum Approval</span>
                        <div className={css.flexColumn2}>
                            <div className={css.gridRowSupport}>
                                <div className={css.increaseDecreaseInput}>
                                    <img
                                        className={css.icon}
                                        src={minusImg}
                                        alt="Decrease"
                                        onClick={() => {
                                            if (data.minimumApproval > 1) {
                                                handleChange("minimumApproval", data.minimumApproval - 1);
                                            }
                                        }}
                                    />
                                    <input
                                        className={css.input}
                                        type="text"
                                        value={data.minimumApproval}
                                        onChange={(e) => {
                                            if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) <= maxAddresses) {
                                                handleChange("minimumApproval", parseInt(e.target.value));
                                            }
                                        }}
                                    />
                                    <img
                                        className={css.icon}
                                        src={plusImg}
                                        alt="Increase"
                                        onClick={() => {
                                            if (data.minimumApproval < maxAddresses) {
                                                handleChange("minimumApproval", data.minimumApproval + 1);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={css.minimumApproval}>
                                    <Progress
                                        className={css.minimumApprovalProgress}
                                        percent={Math.round((data.minimumApproval * 100) / maxAddresses)}
                                        showInfo={false}
                                    />
                                    <Tag className={css.minimumApprovalAddress}>
                                        {data.minimumApproval} of {maxAddresses} addresses
                                    </Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div className={css.buttons}>
                <Link to={`/tedaasV3/buildYourDAO/defineMembership`}>
                    <Button type="default" className={css.button}>
                        {"<"} Back
                    </Button>
                </Link>
                <Button type="primary" className={css.button} onClick={handleNext}>
                    {"Next >"}
                </Button>
            </div>
        </div>
    );
}

export default SelectGovernanceSettings;
