import React, { useEffect, useState } from "react";
import css from "./ProposalSetActions.module.scss";
import DAOStudioProgress from "../../../Components/UI/DAOStudioProgress/DAOStudioProgress";
import { useNavigate } from "react-router-dom";
import { Button, Dropdown } from "antd";
import emptyLogo from "../../../TeDaaS-Assets/DaoCreate/empty.svg";
import menuLogo from "../../../TeDaaS-Assets/proposalCreate/menu.svg";
import downLogo from "../../../TeDaaS-Assets/proposalCreate/down.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import plusImg from "../../../TeDaaS-Assets/DaoCreate/plus.svg";
import minusImg from "../../../TeDaaS-Assets/DaoCreate/minus.svg";
import { useDispatch, useSelector } from "react-redux";
import { proposalCreationConfigureVal } from "../../../../../redux/actions/proposalCreationAction";
import ProposalCreationAddActionModal from "../../../Components/Modals/ProposalCreationModals/ProposalCreationAddActionModal/ProposalCreationAddActionModal";
import { showErrorToast, showWarningToast } from "../../../../../services/notificationService";
import { UpOutlined } from "@ant-design/icons";
import ProposalWalletCard from "../../../Components/UI/ProposalWalletCard/ProposalWalletCard";
import { useDAOAction } from "../../../Hooks/useDAOAction";

const ProposalSetActions = () => {
    const fetchedData = useSelector((state) => state.pc);
    const daoData = useSelector((state) => state.dd);
    const dispatch = useDispatch();
    const { checkDAOTreasury } = useDAOAction();
    const [treasury, setTreasury] = useState([]);
    const navigateTo = useNavigate();
    const [actions, setActions] = useState(fetchedData.actions);
    const [summary, setSummary] = useState(true);
    const [addWallets, setAddWallets] = useState(fetchedData.actions.addWallets);
    const [removeWallets, setRemoveWallets] = useState(fetchedData.actions.removeWallets);
    const handleNext = () => {
        dispatch(proposalCreationConfigureVal("actions", actions));
        navigateTo("/tedaasV3/createProposal/reviewProposal");
    };
    const [isOptions, setIsOptions] = useState({
        mint: "none",
    });
    const handleWithdrawChange = (key, value, index, bal=0) => {
        var temp = [...actions.withdraw];
        temp[index][key] = value;
        if(key=="tokenAddress"){
            temp[index]['balance'] = bal;
        }
        if(key=="tokens"){
            if(value==="max"){
                temp[index]['tokens'] = temp[index]['balance'];
            }else if(value>temp[index]['balance']){
                showWarningToast("Tokens value exceeds the balance");
                temp[index]['tokens'] = temp[index]['balance'];
            }
        }
        setActions({ ...actions, withdraw: temp });
    };
    const handleWithdrawReset = (index) => {
        var temp = [...actions.withdraw];
        temp[index] = {
            address: "",
            tokenAddress: "",
            tokens: 0,
        };
        setActions({ ...actions, withdraw: temp });
    };
    const handleMintReset = () => {
        var temp = [];
        for (let i of actions.mint) {
            temp.push({
                address: "",
                tokens: 0,
            });
        }
        setActions({ ...actions, mint: temp });
    };
    const handleMintChange = (key, value, index) => {
        let arr = actions.mint;
        if (key === "tokens") {
            var diff = value - arr[index].tokens;
            arr[index].tokens = value;
            setActions({ ...actions, mint: arr, totalTokens: actions.totalTokens + diff });
        } else {
            arr[index].address = value;
            setActions({ ...actions, mint: arr });
        }
    };
    const handleMintRemove = (index) => {
        let arr = actions.mint.filter((mint, i) => i != index);
        let decreaseToken = actions.mint[index].tokens;
        setActions({ ...actions, mint: arr, totalTokens: actions.totalTokens - decreaseToken });
    };
    const handlePasteClick = async (i, type = "withdraw") => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            if (typeof clipboardText === "string") {
                if (type === "mint") {
                    handleMintChange("address", clipboardText, i);
                } else {
                    handleWithdrawChange("address", clipboardText, i);
                }
            } else {
                showWarningToast("Clipboard content is not a string!");
            }
        } catch (err) {
            showErrorToast("Failed to read clipboard contents");
        }
    };
    const handleTreasury = async() => {
        const temp = await checkDAOTreasury(daoData.daoAddress);
        setTreasury(temp);
    }
    useEffect(() => {
        if (fetchedData.type === "settings") {
            navigateTo("/tedaasV3/createProposal/reviewProposal");
        }
        setActions({ ...actions, addWallets: addWallets, removeWallets: removeWallets });
    }, [addWallets, removeWallets]);
    useEffect(()=>{
        handleTreasury();
    },[])

    return (
        <div className={css.mainContainer}>
            <div className={css.container}>
                <DAOStudioProgress
                    title={"Set Actions"}
                    description={"These actions are executed if the parameters you set for them are met."}
                    progress={75}
                />
                {daoData.governanceParticipation === "tokenHolders" && actions.mint.length === 0 && actions.withdraw.length === 0 && (
                    <div className={css.empty}>
                        <img className={css.logo} src={emptyLogo} alt="Add Action" />
                        <div className={css.textContainer}>
                            <span className={css.label}>Add Action</span>
                            <span className={css.description}>
                                This action will execute if the vote passes. A common automatic action is transferring funds to a guild or person if
                                their proposal passes a vote
                            </span>
                        </div>
                        <Button
                            className={css.button}
                            type="primary"
                            onClick={() => {
                                dispatch(proposalCreationConfigureVal("addActionModal", true));
                            }}
                        >
                            + Add Action
                        </Button>
                    </div>
                )}
                {daoData.governanceParticipation === "tokenHolders" && (actions.mint.length > 0 || actions.withdraw.length > 0) && (
                    <div className={css.actions}>
                        <span className={css.optionWinText}>After an option wins:</span>
                        {actions.withdraw.map((withdraw, i) => {
                            return (
                                <div key={i} className={css.withdraw}>
                                    <div className={css.withdrawHeader}>
                                        <div className={css.withdrawHeaderLeft}>
                                            <span className={css.label}>Withdraw Assets</span>
                                            <span className={css.description}>Withdraw assets from the DAO treasury</span>
                                        </div>
                                        <div className={css.withdrawHeaderRight}>
                                            <div
                                                className={css.menu}
                                                onClick={() => {
                                                    setIsOptions({
                                                        ...isOptions,
                                                        ["withdrawFloat" + i]: isOptions["withdrawFloat" + i] === "grid" ? "none" : "grid",
                                                    });
                                                }}
                                            >
                                                <img className={css.icon} src={menuLogo} alt="Menu" />
                                            </div>
                                            <div className={css.withdrawHeaderFloat} style={{ display: isOptions["withdrawFloat" + i] || "none" }}>
                                                <span
                                                    className={css.option}
                                                    onClick={() => {
                                                        setActions({
                                                            ...actions,
                                                            withdraw: [
                                                                ...actions.withdraw,
                                                                {
                                                                    address: "",
                                                                    tokenAddress: "",
                                                                    tokens: 0,
                                                                    balance: 0,
                                                                },
                                                            ],
                                                        });
                                                        setIsOptions({ ...isOptions, ["withdrawFloat" + i]: "none" });
                                                    }}
                                                >
                                                    Duplicate Action
                                                </span>
                                                <span
                                                    className={css.option}
                                                    onClick={() => {
                                                        handleWithdrawReset(i);
                                                        setIsOptions({ ...isOptions, ["withdrawFloat" + i]: "none" });
                                                    }}
                                                >
                                                    Reset Action
                                                </span>
                                                <span
                                                    className={css.option}
                                                    onClick={() => {
                                                        setActions({ ...actions, withdraw: [] });
                                                        setIsOptions({ ...isOptions, ["withdrawFloat" + i]: "none" });
                                                    }}
                                                >
                                                    Remove Entire Section
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className={css.line}></div>
                                    <div className={css.withdrawCard}>
                                        <span className={css.label}>Recipient</span>
                                        <div className={css.inputContainer}>
                                            <input
                                                type="text"
                                                className={css.input}
                                                value={withdraw.address}
                                                placeholder="Type wallet address or ENS Name..."
                                                onChange={(e) => {
                                                    handleWithdrawChange("address", e.target.value, i);
                                                }}
                                            />
                                            <span
                                                className={css.paste}
                                                onClick={() => {
                                                    handlePasteClick(i);
                                                }}
                                            >
                                                Paste
                                            </span>
                                        </div>
                                        <span className={css.note}>The wallet that receives the tokens</span>
                                    </div>
                                    <div className={css.withdrawCard}>
                                        <span className={css.label}>Token</span>
                                        <div className={css.selectContainer}>
                                            <div
                                                className={css.selected}
                                                onClick={() => {
                                                    setIsOptions({
                                                        ...isOptions,
                                                        ["withdrawDropdown" + i]: isOptions["withdrawDropdown" + i] === "grid" ? "none" : "grid",
                                                    });
                                                }}
                                            >
                                                <span className={withdraw.tokenAddress === "" ? css.placeholder : css.input}>
                                                    {withdraw.tokenAddress === "" ? "Select a token" : withdraw.tokenAddress}
                                                </span>
                                                <img className={css.icon} src={downLogo} alt="Select Token" />
                                            </div>
                                            <div className={css.options} style={{ display: isOptions["withdrawDropdown" + i] || "none" }}>
                                                {treasury.map((tokenAdd, j) => {
                                                    return (
                                                        <span
                                                            key={j}
                                                            className={css.option}
                                                            onClick={() => {
                                                                if (withdraw.tokenAddress !== tokenAdd.tokenAddress) {
                                                                    handleWithdrawChange("tokenAddress", tokenAdd.tokenAddress, i, tokenAdd.balance);
                                                                    setIsOptions({ ...isOptions, ["withdrawDropdown" + i]: "none" });
                                                                }
                                                            }}
                                                        >
                                                            {tokenAdd.tokenAddress}
                                                        </span>
                                                    );
                                                })}
                                            </div>
                                        </div>
                                        <span className={css.note}>Token to withdraw</span>
                                    </div>
                                    <div className={css.withdrawCard}>
                                        <span className={css.label}>Amount</span>
                                        <div className={css.inputContainer}>
                                            <input
                                                type="text"
                                                className={css.input}
                                                value={withdraw.tokens}
                                                onChange={(e) => {
                                                    if (/^[0-9]*\.?[0-9]+$/.test(e.target.value) && e.target.value.length <= 5) {
                                                        handleWithdrawChange("tokens", parseInt(e.target.value), i);
                                                    }
                                                }}
                                            />
                                            <span
                                                className={css.max}
                                                onClick={() => {
                                                    handleWithdrawChange("tokens", "max", i);
                                                }}
                                            >
                                                Max
                                            </span>
                                        </div>
                                        <span className={css.note}>Amount is calculated in number of tokens, not fiat currency value</span>
                                    </div>
                                </div>
                            );
                        })}
                        {actions.mint.length !== 0 && (
                            <div className={css.mint}>
                                <div className={css.withdrawHeader}>
                                    <div className={css.withdrawHeaderLeft}>
                                        <span className={css.label}>Mint Tokens</span>
                                        <span className={css.description}>
                                            Create a Proposal to mint more governance tokens. Select the wallets that should receive tokens and
                                            determine how many
                                        </span>
                                    </div>
                                    <div className={css.withdrawHeaderRight}>
                                        <div
                                            className={css.menu}
                                            onClick={() => {
                                                setIsOptions({
                                                    ...isOptions,
                                                    mint: isOptions.mint === "none" ? "grid" : "none",
                                                });
                                            }}
                                        >
                                            <img className={css.icon} src={menuLogo} alt="Menu" />
                                        </div>
                                        <div className={css.withdrawHeaderFloat} style={{ display: isOptions.mint || "none" }}>
                                            <span
                                                className={css.option}
                                                onClick={() => {
                                                    handleMintReset();
                                                    setIsOptions({ ...isOptions, mint: "none" });
                                                }}
                                            >
                                                Reset Action
                                            </span>
                                            <span
                                                className={css.option}
                                                onClick={() => {
                                                    setActions({ ...actions, mint: [] });
                                                    setIsOptions({ ...isOptions, mint: "none" });
                                                }}
                                            >
                                                Remove Entire Section
                                            </span>
                                        </div>
                                    </div>
                                </div>
                                <div className={css.line}></div>
                                <ul className={css.list}>
                                    <li>The total token supply includes all tokens you have already minted</li>
                                </ul>
                                <div className={css.walletSetting}>
                                    <div className={css.mintRow}>
                                        <span className={css.label}>Address</span>
                                        <span className={css.label}>Tokens</span>
                                        <span className={css.label}>Allocation</span>
                                    </div>
                                    {actions.mint.map((mint, i) => {
                                        return (
                                            <div key={i} className={css.mintRow}>
                                                <div className={css.inputContainer}>
                                                    <input
                                                        type="text"
                                                        className={css.input}
                                                        value={mint.address}
                                                        placeholder="Type wallet address or ENS Name..."
                                                        onChange={(e) => {
                                                            handleMintChange("address", e.target.value, i);
                                                        }}
                                                    />
                                                    <span
                                                        className={css.paste}
                                                        onClick={() => {
                                                            handlePasteClick(i, "mint");
                                                        }}
                                                    >
                                                        Paste
                                                    </span>
                                                </div>
                                                <div className={css.increaseDecreaseInput}>
                                                    <img
                                                        className={css.icon}
                                                        src={minusImg}
                                                        alt="Decrease"
                                                        onClick={() => {
                                                            if (mint.tokens > 1) {
                                                                handleMintChange("tokens", mint.tokens - 1, i);
                                                            }
                                                        }}
                                                    />
                                                    <input className={css.input} type="text" readOnly value={mint.tokens} />
                                                    <img
                                                        className={css.icon}
                                                        src={plusImg}
                                                        alt="Increase"
                                                        onClick={() => {
                                                            handleMintChange("tokens", mint.tokens + 1, i);
                                                        }}
                                                    />
                                                </div>
                                                <span
                                                    className={css.allocation}
                                                >{`${mint.tokens === 0 ? 0 : ((mint.tokens / actions.totalTokens) * 100).toFixed(2)}%`}</span>
                                                <div
                                                    className={css.menuContainer}
                                                    onClick={() => {
                                                        handleMintRemove(i);
                                                    }}
                                                >
                                                    <img className={css.icon} src={menuImg} alt="Menu" />
                                                </div>
                                            </div>
                                        );
                                    })}
                                    <div className={css.mintRow}>
                                        <span className={css.label}>{actions.mint.length} Address</span>
                                        <span className={css.label}>{actions.totalTokens || 0} Tokens</span>
                                        <span className={css.label}>{actions.totalTokens === 0 ? 0 : 100}%</span>
                                    </div>
                                </div>
                                <Button
                                    className={css.whiteButton}
                                    type="default"
                                    onClick={() => {
                                        setActions({ ...actions, mint: [...actions.mint, { address: "", tokens: 0 }] });
                                    }}
                                >
                                    Add Wallet
                                </Button>
                                <div className={css.walletInfo}>
                                    <div
                                        className={css.walletInfoHeader}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setSummary(!summary);
                                        }}
                                    >
                                        <span>Summary</span>
                                        <UpOutlined className={css.icon} />
                                    </div>
                                    {summary && <div className={css.line}></div>}
                                    {summary && (
                                        <div className={css.walletInfoCard}>
                                            <span className={css.name}>New Tokens</span>
                                            <span className={css.value}>+{actions.totalTokens} EXP</span>
                                        </div>
                                    )}
                                    {summary && (
                                        <div className={css.walletInfoCard}>
                                            <span className={css.name}>New Holders</span>
                                            <span className={css.value}>+{actions.mint.length}</span>
                                        </div>
                                    )}
                                    {summary && (
                                        <div className={css.walletInfoCard}>
                                            <span className={css.name}>Total Tokens</span>
                                            <span className={css.value}>{actions.totalTokens}</span>
                                        </div>
                                    )}
                                    {summary && (
                                        <div className={css.walletInfoCard}>
                                            <span className={css.name}>Total Holders</span>
                                            <span className={css.value}>{actions.mint.length}</span>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {(daoData.governanceParticipation === "tokenHolders" && actions.mint.length !== 0) ||
                    (actions.withdraw.length !== 0 && (
                        <Button
                            className={css.button}
                            type="primary"
                            onClick={() => {
                                dispatch(proposalCreationConfigureVal("addActionModal", true));
                            }}
                        >
                            + Add Action
                        </Button>
                    ))}
                {daoData.governanceParticipation === "multisigMembers" && (
                    <ProposalWalletCard editable={true} type="addWallets" addresses={addWallets} setAddresses={setAddWallets} />
                )}
                {daoData.governanceParticipation === "multisigMembers" && (
                    <ProposalWalletCard editable={true} type="removeWallets" addresses={removeWallets} setAddresses={setRemoveWallets} />
                )}
                <div className={css.flexRowBetween}>
                    <Button
                        type="default"
                        className={css.button}
                        onClick={() => {
                            navigateTo("/tedaasV3/createProposal/proposalVote");
                        }}
                    >
                        {"< Back"}
                    </Button>
                    <Button type="primary" className={css.button} onClick={handleNext}>
                        {"Next >"}
                    </Button>
                </div>
            </div>
            {fetchedData.addActionModal && <ProposalCreationAddActionModal actions={actions} setActions={setActions} />}
        </div>
    );
};

export default ProposalSetActions;
