import React from "react";
import css from "./ProposalWalletCard.module.scss";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import copyImg from "../../../TeDaaS-Assets/proposalCreate/copy.svg";
import data from "./ProposalWalletCardData.json";
import { Button } from "antd";

function ProposalWalletCard({
    type = "addWallets",
    addresses = [],
    setAddresses = () => {},
    editable = false,
    minimumApproval = 1,
    setMinimumApproval = () => {},
}) {
    const handlePasteClick = async (i) => {
        try {
            const clipboardText = await navigator.clipboard.readText();
            if (typeof clipboardText === "string") {
                let temp = [...addresses];
                temp[i] = clipboardText;
                setAddresses(temp);
            } else {
                showWarningToast("Clipboard content is not a string!");
            }
        } catch (err) {
            showErrorToast("Failed to read clipboard contents");
        }
    };
    return (
        <div className={css.card}>
            <div className={css.header}>
                <span className={css.title}>{data[type].name}</span>
                <span className={css.description}>{data[type].description}</span>
            </div>
            {(type === "addWallets" || type === "removeWallets") && (
                <div className={css.row2}>
                    <span className={css.addressLabel}>Address</span>
                    <div className={css.addressMain}>
                        {addresses.map((val, i) => {
                            return (
                                <div className={css.addressContainer} key={i}>
                                    <div className={css.address}>
                                        <input
                                            className={css.addressInput}
                                            type="text"
                                            value={val}
                                            readOnly={!editable}
                                            placeholder="0xB178...4b33"
                                            onChange={(e) => {
                                                let temp = [...addresses];
                                                temp[i] = e.target.value;
                                                setAddresses(temp);
                                            }}
                                        />
                                        {editable && (
                                            <img
                                                className={css.copyImg}
                                                onClick={() => {
                                                    handlePasteClick(i);
                                                }}
                                                src={copyImg}
                                                alt="Copy to Clipboard"
                                            />
                                        )}
                                    </div>
                                    {editable && (
                                        <div
                                            className={css.menu}
                                            onClick={() => {
                                                let temp = [...addresses];
                                                temp.splice(i, 1);
                                                setAddresses(temp);
                                            }}
                                        >
                                            <img className={css.menuImg} src={menuImg} alt="Menu" />
                                        </div>
                                    )}
                                </div>
                            );
                        })}
                    </div>
                </div>
            )}
            {(type === "addWallets" || type === "removeWallets") && editable && (
                <Button
                    type="default"
                    className={css.button}
                    onClick={() => {
                        let temp = [...addresses];
                        temp.push("");
                        setAddresses(temp);
                    }}
                >
                    Add Wallet
                </Button>
            )}
            {(type === "addWallets" || type === "removeWallets") && (
                <div className={css.summaryContainer}>
                    <span className={css.summaryHeader}>Summary</span>
                    <div className={css.line}></div>
                    <div className={css.row}>
                        <span className={css.label}>Total Wallets</span>
                        <span className={css.value}>{addresses.length}</span>
                    </div>
                </div>
            )}
        </div>
    );
}

export default ProposalWalletCard;
