import React, { useEffect } from "react";
import deployDaoImg from "../../../TeDaaS-Assets/DaoCreate/deployDao.svg";
import searchImg from "../../../TeDaaS-Assets/DaoCreate/search.svg";
import robotImg from "../../../TeDaaS-Assets/DaoCreate/robot.svg";
import loadingImg from "../../../TeDaaS-Assets/DaoCreate/loading.svg";
import css from "./DAODeploying.module.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import usePostDAOData from "../../../Hooks/usePostDAOData";
import { useDAOAction } from "../../../Hooks/useDAOAction";
import { daoCreationReset } from "../../../../../redux/actions/daoCreationAction";

function DAODeploying() {
    const approved = useSelector((state) => state.dc.approved);
    const navigateTo = useNavigate();
    const dispatch = useDispatch();
    const { createDAO } = useDAOAction();
    const deployDAO = async() => {
        const res = await createDAO();
        if(res) {
            dispatch(daoCreationReset());
            navigateTo("/tedaasV3/daoDashboard");
        }
    }
    useEffect(() => {
        if (!approved) {
            navigateTo("/tedaasV3/daoDashboard");
        }else {
            deployDAO();
        }
    }, [approved]);
    return (
        <div className={css.container}>
            <img className={css.deployIcon} src={deployDaoImg} alt="Deploying" />
            <div className={css.body}>
                <span className={css.title}>Building your DAO</span>
                <div className={css.content}>
                    <div className={css.row}>
                        <img className={css.icon} src={searchImg} alt="Looking through your data" />
                        <span className={css.text}>Looking through your data</span>
                    </div>
                    <div className={css.row}>
                        <img className={css.icon} src={robotImg} alt="Sorting it through our algorithms" />
                        <span className={css.text}>Sorting it through our algorithms</span>
                    </div>
                    <div className={css.row}>
                        <img className={`${css.icon} ${css.rotate}`} src={loadingImg} alt="Writing and running queries" />
                        <span className={css.text}>Writing and running queries</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DAODeploying;
