import axios from "axios";
import { ethers } from "ethers";
import { useDispatch, useSelector } from "react-redux";
import { JSON_SERVER_ENDPOINT, TEDAAS_SCHEMAS } from "../Constants/Constants";
import usePostTokenData from "../Hooks/usePostTokenData";
import { setTokenPaused, updateBalanceAmount, updateStakedAmount, updateTotalSupply } from "../../../redux/actions/txDataAction";
import { showErrorToast, showSuccessToast, showWarningToast } from "../../../services/notificationService";
import { useState } from "react";
import {
    getEntities,
    getFilteredEntities,
    getTokenHolderFromJSONSERVER,
    getTokenHoldersFromJSONSERVER,
    postTokenHoldersFromJSONSERVER,
} from "../../../services/stf";
import { ERC20ContractABI } from "../Constants/ERC20ContractABI";
import { useParams } from "react-router-dom";

export const useERC20TokenAction = () => {
    const signer = useSelector((state) => state.auth.signer);
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const { postNewTokenTxn, postTokenHolders } = usePostTokenData();
    // const userAllTokenDetails = useSelector((state) => state.tokenDataReducer.userAllTokens);
    const tokenDetails = useSelector((state) => state.td);
    const { address } = useParams();
    // const [contract, setContract] = useState(new ether)
    //updated
    const createContractInstance = (tokenAddress, signer) => {
        try {
            const contractAbi = tokenDetails.abi;
            // const contractAbi = ERC20ContractABI;
            const contract = new ethers.Contract(tokenAddress, contractAbi, signer);
            return { contract, contractAbi };
        } catch (e) {
            console.log("Error in Create Contract Instance", e);
            throw Error("\nError in Creating Contract Instance > " + e);
        }
    };

    //updated
    const isValidAddress = (address) => {
        const res = ethers.utils.isAddress(address);
        return res;
    };

    //updated
    const getAddressBalance = async (tokenAddress) => {
        try {
            const valid = isValidAddress(tokenAddress);
            if (!valid) {
                return false;
            }
            const paused = await isPaused(tokenAddress);
            if (paused) {
                return paused;
            }
            // const isOwner = userAllTokenDetails.find((item)=>item.address===tokenAddress).owner===address
            const { contract } = createContractInstance(tokenAddress, signer);
            let balance = await contract.balanceOf(signerAddress);
            balance = parseInt(ethers.utils.formatUnits(balance, 18));
            // dispatch(updateBalanceAmount(balance));
            return balance;
        } catch (e) {
            throw Error("\nError in Get Address Balance > " + e);
        }
    };

    //updated
    const approveAddressToTransactOnBehalf = async (address, receipientAddress, amount) => {
        try {
            const balance = await getAddressBalance(signerAddress);
            if (balance === false || balance === true) {
                return balance;
            }
            const isValidReceipient = isValidAddress(receipientAddress);
            if (!isValidReceipient) {
                return false;
            }
            const EtherToWei = ethers.utils.parseUnits(String(amount), "ether");
            const { contract, contractAbi } = createContractInstance(address, signer);
            const approve = await contract.approve(receipientAddress, EtherToWei);
            await approve.wait();
            const txHash = approve.hash;
            const decimalValue = parseInt(approve.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                address,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                amount,
                signerAddress,
                receipientAddress,
                "Approve address to Transact on Behalf",
                "Approve address to Transact on Behalf",
            );
            let senderBalance = await contract.balanceOf(signerAddress);
            senderBalance = parseInt(ethers.utils.formatUnits(senderBalance, 18));
            await postTokenHolders(address, signerAddress, senderBalance);
            let receiverBalance = await contract.balanceOf(receipientAddress);
            receiverBalance = parseInt(ethers.utils.formatUnits(receiverBalance, 18));
            await postTokenHolders(address, receipientAddress, receiverBalance);
            return true;
        } catch (e) {
            console.log(e);
            return false;
            throw Error("\nError in Approve Address To Transact On Behalf > " + e);
        }
    };

    //updated
    const isPaused = async (address) => {
        try {
            const { contract } = createContractInstance(address, signer);
            const paused = await contract.paused();
            // dispatch(setTokenPaused(paused));
            return paused;
        } catch (e) {
            console.log(e);
            return "Error in checking Token Pause Status";
            // throw Error("\nError in Checking Token Pause > " + e);
        }
    };

    const blacklistAddress = async (tokenAddress, blacklistAddress) => {
        try {
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.blackListUser(blacklistAddress);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                0, //amount
                signerAddress,
                blacklistAddress,
                "Blacklist Address",
                "Blacklist Address",
            );
            return true;
        } catch (e) {
            console.log(e);
            return "Error in Blacklisting Address";
            // throw Error("\nError in Blacklisting Address > " + e);
        }
    };

    const whitelistAddress = async (tokenAddress, blacklistAddress) => {
        try {
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.whiteListUser(blacklistAddress);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                0, //amount
                signerAddress,
                blacklistAddress,
                "Whitelist Address",
                "Whitelist Address",
            );
            return true;
        } catch (e) {
            console.log(e);
            return "Error in Whitelisting Address";
        }
    };

    const burnToken = async (tokenAddress, amount) => {
        try {
            const EtherToWei = ethers.utils.parseUnits(String(amount), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.burnSupply(EtherToWei);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                amount, //amount
                signerAddress,
                tokenAddress,
                "Burn Token",
            );
            // await getTokenTransactionDetails(auth.userAddress, tokenAddress);
            return true;
        } catch (e) {
            return "Error in Burning Token";
        }
    };

    const stakeToken = async (tokenAddress, amount, period) => {
        try {
            const EtherToWei = ethers.utils.parseUnits(String(amount), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.stake(EtherToWei, period);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                amount, //amount
                signerAddress,
                tokenAddress,
                "Stake Token",
                "Stake Token",
            );
            let balance = await contract.balanceOf(signerAddress);
            balance = parseInt(ethers.utils.formatUnits(balance, 18));
            await postTokenHolders(tokenAddress, signerAddress, balance);
            // await getTokenTransactionDetails(auth.userAddress, tokenAddress);
            return true;
        } catch (e) {
            return "Error in Staking Token";
        }
    };

    const mintToken = async (tokenAddress, amount) => {
        try {
            const EtherToWei = ethers.utils.parseUnits(String(amount), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.mintSupply(EtherToWei);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                amount, //amount
                signerAddress,
                tokenAddress,
                "Mint Token",
            );
            // await getTokenTransactionDetails(auth.userAddress, tokenAddress);
            return true;
        } catch (e) {
            return "Error in Minting Token";
        }
    };

    const changeTokenOwner = async (tokenAddress, newOwner) => {
        try {
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.transferOwnership(newOwner);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(tokenAddress, contractAbi, txHash, GasgweiValue.toFixed(2), 0, signerAddress, newOwner, "Change Owner");
            await postTokenHolders(tokenAddress, newOwner, 0);
            // await getTokenTransactionDetails(auth.userAddress, tokenAddress);
            return true;
        } catch (e) {
            return "Error in Changing Token Owner";
        }
    };

    const withdrawStakeAmount = async (tokenAddress, isWithdrawFull, value = 0) => {
        try {
            console.log(isWithdrawFull);
            const EtherToWei = ethers.utils.parseUnits(String(value), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            let tx;
            let val = 0;
            if (isWithdrawFull) {
                tx = await contract.unstakeAll();
                val = await getAddressStakedAmount(tokenAddress);
            } else {
                tx = await contract.unstake(EtherToWei);
                val = value;
            }
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                val,
                signerAddress,
                tokenAddress,
                isWithdrawFull ? "Withdraw Full" : "Withdraw Token",
            );
            // await getTokenTransactionDetails(auth.userAddress, tokenAddress);
            return true;
        } catch (e) {
            throw Error("\nError in Withdraw Stake Amount > " + e);
        }
    };

    const pauseUnpauseToken = async (tokenAddress, pause) => {
        try {
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            let txHash;
            let GasgweiValue;
            const isTokenPaused = await isPaused(tokenAddress);
            if (!isTokenPaused) {
                const tx = await contract.pauseTokenTransfers();
                txHash = tx.hash;
                const decimalValue = parseInt(tx.gasPrice, 16);
                GasgweiValue = decimalValue / 1e9;
                await tx.wait();
            } else {
                const tx = await contract.unPauseTokenTransfers();
                txHash = tx.hash;
                const decimalValue = parseInt(tx.gasPrice, 16);
                GasgweiValue = decimalValue / 1e9;
                await tx.wait();
            }
            await postNewTokenTxn(
                tokenAddress,
                contractAbi,
                txHash,
                GasgweiValue.toFixed(2),
                0,
                signerAddress,
                tokenAddress,
                isTokenPaused ? "Paused Token" : "Unpaused Token",
            );
            return true;
        } catch (e) {
            throw Error("\nError in Pause / Unpase Token > " + e);
        }
    };

    const transferToken = async (tokenAddress, to, value) => {
        try {
            console.log("Transfer Function", tokenAddress, to ,value)
            const EtherToWei = ethers.utils.parseUnits(String(value), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const tx = await contract.transfer(to, EtherToWei);
            await tx.wait();
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(tokenAddress, contractAbi, txHash, GasgweiValue.toFixed(2), value, signerAddress, to, "Token Transfer");
            let senderBalance = await contract.balanceOf(signerAddress);
            senderBalance = parseInt(ethers.utils.formatUnits(senderBalance, 18));
            await postTokenHolders(tokenAddress, signerAddress, senderBalance);
            let receiverBalance = await contract.balanceOf(to);
            receiverBalance = parseInt(ethers.utils.formatUnits(receiverBalance, 18));
            await postTokenHolders(tokenAddress, to, receiverBalance);
            return true;
        } catch (e) {
            console.log(e);
            throw Error("\nError in Transfer Token > " + e);
        }
    };

    const transferTokenFromTokenBalance = async (tokenAddress, to, value) => {
        try {
            console.log("Transfer From Token Function", tokenAddress, to ,value)
            const EtherToWei = ethers.utils.parseUnits(String(value), "ether");
            const { contract, contractAbi } = createContractInstance(tokenAddress, signer);
            const balance = await contract.balanceOf(tokenAddress);
            const tokenBalance = parseInt(ethers.utils.formatUnits(balance, 18));
            console.log(tokenBalance, "Balance");
            const tx = await contract.transferTokensToUser(to, EtherToWei, 3);
            console.log("TX");
            await tx.wait();
            console.log("TX Wait");
            const txHash = tx.hash;
            const decimalValue = parseInt(tx.gasPrice, 16);
            const GasgweiValue = decimalValue / 1e9;
            await postNewTokenTxn(tokenAddress, contractAbi, txHash, GasgweiValue.toFixed(2), value, signerAddress, to, "Token Transfer");
            let senderBalance = await contract.balanceOf(signerAddress);
            senderBalance = parseInt(ethers.utils.formatUnits(senderBalance, 18));
            await postTokenHolders(tokenAddress, signerAddress, senderBalance);
            let receiverBalance = await contract.balanceOf(to);
            receiverBalance = parseInt(ethers.utils.formatUnits(receiverBalance, 18));
            await postTokenHolders(tokenAddress, to, receiverBalance);
            return true;
        } catch (e) {
            console.log(e.message);
            throw Error("\nError in Transfer Token > " + e);
        }
    };

    const getAddressStakedAmount = async (tokenAddress, type = "UT") => {
        try {
            const { contract } = createContractInstance(tokenAddress, signer);
            let stakedAmount = type == "GT" ? await contract.userStakedBalance(signerAddress) : await contract.totalStaked(signerAddress);
            stakedAmount = parseInt(ethers.utils.formatUnits(stakedAmount, 18));
            console.log(type, stakedAmount);
            // dispatch(updateStakedAmount(stakedAmount));
            return stakedAmount;
        } catch (e) {
            throw Error("\nError in Get Address Staked Amount > " + e);
        }
    };

    const getTotalSuppy = async (tokenAddress) => {
        try {
            const { contract } = createContractInstance(tokenAddress, signer);
            let totalSupply = await contract.totalSupply();
            totalSupply = parseInt(ethers.utils.formatUnits(totalSupply, 18));
            // dispatch(updateTotalSupply(totalSupply));
            return totalSupply;
        } catch (e) {
            throw Error("\nError in Get Total Supply > " + e);
        }
    };

    const getOwner = async (tokenAddress) => {
        try {
            const { contract } = createContractInstance(tokenAddress, signer);
            const owner = await contract.owner();
            return owner;
        } catch (e) {
            throw Error("\nError in Get Owner > " + e);
        }
    };
    const checkBlacklist = async (tokenAddress, address = "") => {
        try {
            const { contract } = createContractInstance(tokenAddress, signer);
            const blacklist = await contract.blackListedAddress(address.length > 0 ? address : signerAddress);
            return blacklist;
        } catch (e) {
            throw Error("\nError in checking Blacklist > " + e);
        }
    };

    return {
        checkBlacklist,
        getAddressBalance,
        isPaused,
        approveAddressToTransactOnBehalf,
        isValidAddress,
        blacklistAddress,
        whitelistAddress,
        burnToken,
        mintToken,
        changeTokenOwner,
        withdrawStakeAmount,
        pauseUnpauseToken,
        transferToken,
        transferTokenFromTokenBalance,
        getAddressStakedAmount,
        getTotalSuppy,
        getOwner,
        stakeToken,
    };
};
