import React, { useState } from "react";
import css from "./ProposalReviewProposal.module.scss";
import DAOStudioProgress from "../../../Components/UI/DAOStudioProgress/DAOStudioProgress";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import linkLogo from "../../../TeDaaS-Assets/DaoCreate/redirect.svg";
import dummyLogo from "../../../TeDaaS-Assets/DaoCreate/dummyLogo.svg";
import menuImg from "../../../TeDaaS-Assets/DaoCreate/menu.svg";
import deployDaoImg from "../../../TeDaaS-Assets/DaoCreate/deployDao.svg";
import { useDispatch, useSelector } from "react-redux";
import { UpOutlined } from "@ant-design/icons";
import ProposalEstimateModal from "../../../Components/Modals/ProposalCreationModals/ProposalCreationAddActionModal/ProposalEstimate/ProposalEstimateModal";
import { proposalCreationConfigureVal, proposalCreationReset } from "../../../../../redux/actions/proposalCreationAction";
import { convertJsonToTimeStamp, copyToClipboard, shortenAddress } from "../../../Constants/reusableFunctions";
import MintTokenAction from "../../../Components/Layout/MintTokenAction/MintTokenAction";
import { updateLoader } from "../../../../../redux/actions/loaderAction";
import { showErrorToast } from "../../../../../services/notificationService";
import { useDAOAction } from "../../../Hooks/useDAOAction";
import DAOEditSettingsAction from "../../../Components/Layout/DAODetails/DAOEditSettingsAction/DAOEditSettingsAction";
import WithdrawAssetsAction from "../../../Components/Layout/WithdrawAssetsAction/WithdrawAssetsAction";
import ProposalWalletCard from "../../../Components/UI/ProposalWalletCard/ProposalWalletCard";

const ProposalReviewProposal = () => {
    const fetchedData = useSelector((state) => state.pc);
    const daoData = useSelector((state) => state.dd);
    const daoSettings = useSelector((state) => state.pa.settings);
    const dispatch = useDispatch();
    const navigateTo = useNavigate();
    const [votingOption, setVotingOption] = useState("breakdown");
    const { createProposal } = useDAOAction();
    const handleNext = async () => {
        dispatch(proposalCreationConfigureVal("createProposalModal", false));
        dispatch(updateLoader(true));
        try {
            const res = await createProposal();
            dispatch(updateLoader(false));
            dispatch(proposalCreationReset());
            navigateTo("/tedaasV3/dao/" + fetchedData.daoAddress);
        } catch (e) {
            showErrorToast("Proposal not created");
            dispatch(updateLoader(false));
        }
    };
    return (
        <div className={css.mainContainer}>
            <div className={css.container}>
                <DAOStudioProgress
                    title={"Review Proposal"}
                    description={"Check to make sure everything is correct before putting it to vote. You can’t edit a proposal once it’s published."}
                    progress={100}
                />
                <div className={css.flexColumnStart4px}>
                    <span className={css.font1624}>Test Proposal</span>
                    <div className={css.flexRowStart4}>
                        <span className={css.font1422}>Published by</span>
                        <span className={css.link}>You</span>
                    </div>
                </div>
                <div className={css.flexColumnStart4px}>
                    <span className={css.font1422} style={{ color: "#00000073" }}>
                        About
                    </span>
                    <span className={css.font1422}>
                        CT0032, CT0013 - Invoice 0000-4518, CT0008 - Invoice Polygon07012024 CT0027 - Invoice Collaboration Agreement CT0028 - Invoice
                        1/2024 CT0001 - Invoices A772DAD3-0001 & A772DAD3-0002 & A772DAD3-0004 & A772DAD3-0007 & A772DAD3-0008
                    </span>
                </div>
                <div className={css.body}>
                    <div className={css.flexColumnStart16px}>
                        <div className={css.card}>
                            <div className={css.flexRowBetween}>
                                <span className={css.font1422}>Voting</span>
                                <div className={css.flexRowCenter0px}>
                                    <span
                                        className={`${css.votingButton} ${votingOption == "breakdown" && css.activeVotingButton}`}
                                        onClick={() => {
                                            if (votingOption !== "breakdown") {
                                                setVotingOption("breakdown");
                                            }
                                        }}
                                    >
                                        Breakdown
                                    </span>
                                    <span
                                        className={`${css.votingButton} ${votingOption == "voters" && css.activeVotingButton}`}
                                        onClick={() => {
                                            if (votingOption !== "voters") {
                                                setVotingOption("voters");
                                            }
                                        }}
                                    >
                                        Voters
                                    </span>
                                    <span
                                        className={`${css.votingButton} ${votingOption == "info" && css.activeVotingButton}`}
                                        onClick={() => {
                                            if (votingOption !== "info") {
                                                setVotingOption("info");
                                            }
                                        }}
                                    >
                                        Info
                                    </span>
                                </div>
                            </div>
                            <div className={css.line}></div>
                            {votingOption === "breakdown" && (
                                <div className={css.flexColumnStart15px}>
                                    <span className={css.font1624}>Rules of Decision</span>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Options</span>
                                        <span>{"Yes, no or abstain"}</span>
                                    </div>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Strategy</span>
                                        <span>{"1 token -> 1 vote"}</span>
                                    </div>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Support Threshold</span>
                                        <span>{"> 50%"}</span>
                                    </div>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Minimum Participation (Quorum)</span>
                                        <span>{"> 0.75 of 5 EXP (15%)"}</span>
                                    </div>
                                </div>
                            )}
                            {votingOption === "voters" && (
                                <div className={css.flexColumnStart15px}>
                                    <span className={css.font1624}>Voting Activity</span>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Current Participation</span>
                                        <span>{"0"}</span>
                                        {/* <span className={css.colorGrey}>{"0.75 missing votes"}</span> */}
                                    </div>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Unique Voters</span>
                                        <span>{"0"}</span>
                                    </div>
                                </div>
                            )}
                            {votingOption === "info" && (
                                <div className={css.flexColumnStart15px}>
                                    <span className={css.font1624}>Duration</span>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>Start</span>
                                        <span>
                                            {fetchedData.voting.startTimeOption === "now"
                                                ? String(new Date())
                                                : String(new Date(Number(fetchedData.voting.startTime)))}
                                        </span>
                                    </div>
                                    <div className={`${css.flexColumnStart4px} ${css.font1422}`}>
                                        <span className={css.colorGrey}>End</span>
                                        <span>
                                            {fetchedData.voting.endTimeOption === "duration"
                                                ? String(
                                                      new Date(
                                                          (fetchedData.voting.startTimeOption === "now"
                                                              ? new Date().getTime()
                                                              : new Date(Number(fetchedData.voting.startTime)).getTime()) +
                                                              convertJsonToTimeStamp(fetchedData.voting.duration),
                                                      ),
                                                  )
                                                : String(new Date(Number(fetchedData.voting.endTime)))}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                        <div className={css.card}>
                            <div className={css.flexRowBetween}>
                                <span className={css.font1422}>Actions</span>
                            </div>
                            {fetchedData.actions.mint.length > 0 && (
                                <MintTokenAction mintAction={fetchedData.actions.mint} daoAddress={fetchedData.daoAddress} />
                            )}
                            {Object.keys(fetchedData.actions.settings).length > 0 && (
                                <DAOEditSettingsAction proposalDao={fetchedData.actions.settings} />
                            )}
                            {fetchedData.actions.withdraw.length > 0 && <WithdrawAssetsAction withdraw={fetchedData.actions.withdraw} />}
                            {fetchedData.actions.addWallets.length > 0 && (
                                <ProposalWalletCard type="addWallets" addresses={fetchedData.actions.addWallets} />
                            )}
                            {fetchedData.actions.removeWallets.length > 0 && (
                                <ProposalWalletCard type="removeWallets" addresses={fetchedData.actions.removeWallets} />
                            )}
                        </div>
                    </div>
                    <div className={css.card}>
                        <div className={css.flexRowBetween}>
                            <span className={css.font1422}>Resources (0)</span>
                            <Button className={`${css.button} ${css.font1220}`} type="default" style={{ background: "#FAFAFA" }}>
                                Add Resource
                            </Button>
                        </div>
                        <div className={css.line}></div>
                        <div className={css.icon240Container}>
                            <img className={css.icon240} src={deployDaoImg} alt="Resources" />
                        </div>
                    </div>
                </div>
                <div className={css.flexRowBetween}>
                    <Button
                        type="default"
                        className={css.button}
                        onClick={() => {
                            if (fetchedData.type === "settings") {
                                navigateTo("/tedaasV3/createProposal/proposalVote");
                            } else {
                                navigateTo("/tedaasV3/createProposal/setActions");
                            }
                        }}
                    >
                        {"< Back"}
                    </Button>
                    <Button
                        type="primary"
                        className={css.button}
                        onClick={() => {
                            dispatch(proposalCreationConfigureVal("createProposalModal", true));
                        }}
                    >
                        {"Next >"}
                    </Button>
                </div>
            </div>
            {fetchedData.createProposalModal && <ProposalEstimateModal handleNext={handleNext} />}
        </div>
    );
};

export default ProposalReviewProposal;
