import { useState } from "react";
import { chainOptions, switchCaseConstants, TEDAAS_SCHEMAS, TOKEN_SIMULATION } from "../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { deleteEntity, insertEntity } from "../../../services/stf";
import { convertJsonToTimeStamp } from "../Constants/reusableFunctions";

const usePostDAOData = () => {
    const dispatch = useDispatch();
    const signer = useSelector((state) => state.auth.signer);
    const auth = useSelector((state) => state.auth);
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const fetchedData = useSelector((state) => state.dc);
    const daoData = useSelector((state) => state.dd);

    const postDAOData = async (daoAddress, tokenAddress) => {
        try {
            console.log("POsting DAO DATA");
            const blockchain = chainOptions.filter((e) => {return e.id==auth.currentChain});
            const data = {
                daoAddress: daoAddress,
                governanceParticipation: fetchedData?.participationInGovernance?.participant,
                earlyExecution: fetchedData?.governanceSettings?.earlyExecution,
                voteChange: fetchedData?.governanceSettings?.voteChange,
                tokenSymbol: fetchedData?.participationInGovernance?.tokenSymbol || "",
                proposalCreation: fetchedData?.governanceSettings?.proposalCreation,
                ensSubdomain: fetchedData?.describe?.ensSubdomain + ".dao.eth",
                tokenName: fetchedData?.participationInGovernance?.tokenName || "",
                description: fetchedData?.describe?.description,
                network: fetchedData?.blockchain?.network,
                tokenAddress: tokenAddress || fetchedData?.participationInGovernance?.contractAddress,
                createdAt: String(new Date().getTime()),
                daoType: fetchedData?.daoType || "token",
                blockchain: blockchain[0].chainName,
                daoName: fetchedData?.describe?.daoName,
                logo: fetchedData?.describe?.logo || "",
                links: fetchedData?.describe?.links || [],
                ownerAddress: fetchedData?.ownerAddress || signerAddress,
                supportThreeshold: fetchedData?.governanceSettings?.supportThreeshold,
                minimumParticipation: fetchedData?.governanceSettings?.minimumParticipation,
                minimumRequirement: fetchedData?.governanceSettings?.minimumRequirement,
                minimumApproval: fetchedData?.governanceSettings?.minimumApproval,
                minimumDuration: String(convertJsonToTimeStamp(fetchedData?.governanceSettings?.minimumDuration)),
                updatedAt: String(new Date().getTime()),
                chainId: auth.currentChain,
            };
            const res = await insertEntity([data], TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const postDAOMembersData = async (members, daoAddress, remove = false) => {
        try {
            if (remove) {
                for (let i of members) {
                    const payload = {
                        daoAddress: daoAddress,
                        userAddress: i.memberAddress,
                    };
                    const res = await deleteEntity(payload, TEDAAS_SCHEMAS.daoMemFollId, TEDAAS_SCHEMAS.accessToken);
                }
                return true;
            }
            const payload = [];
            console.log("DAO Members", members, daoAddress);
            for (let i of members) {
                const data = {
                    daoAddress: daoAddress,
                    userAddress: i.memberAddress,
                    role: "member",
                };
                payload.push(data);
            }
            console.log("DAO MEMBERS PAYLOAD", payload);
            const res = await insertEntity(payload, TEDAAS_SCHEMAS.daoMemFollId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const updateProposal = async (proposal) => {
        try {
            await insertEntity([proposal], TEDAAS_SCHEMAS.proposalId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const updateDAO = async (settings) => {
        try {
            await insertEntity([{ ...daoData, ...settings }], TEDAAS_SCHEMAS.daoId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    return { postDAOData, postDAOMembersData, updateProposal, updateDAO };
};

export default usePostDAOData;
