import React, { useEffect, useState } from "react";
import css from "./DAOEditSettings.module.scss";
import { DeleteFilled } from "@ant-design/icons";
import uploadImg from "../../../../TeDaaS-Assets/DaoCreate/upload.svg";
import generateAIImg from "../../../../TeDaaS-Assets/DaoCreate/generateAI.svg";
import { convertJsonToTimeStamp, extractMinimumDurationFromTimeStamp, handleImageDrop } from "../../../../Constants/reusableFunctions";
import TextArea from "antd/es/input/TextArea";
import { Button, Checkbox, Input, Progress, Slider, Tag } from "antd";
import plusImg from "../../../../TeDaaS-Assets/DaoCreate/plus.svg";
import minusImg from "../../../../TeDaaS-Assets/DaoCreate/minus.svg";
import css2 from "../../../../Pages/DAOCreateForm/DescribeYourDAO/DescribeYourDAO.module.scss";
import css3 from "../../../../Pages/DAOCreateForm/SelectGovernanceSettings/SelectGovernanceSettings.module.scss";
import { useDispatch } from "react-redux";
import { proposalsActionsConfigureVal } from "../../../../../../redux/actions/proposalActionsAction";
import css4 from "../DAODetailsSettings/DAODetailsSettings.module.scss";

function DAOEditSettings({ dao, members }) {
    const minimumDuration = extractMinimumDurationFromTimeStamp(dao.minimumDuration);
    const [updatedDao, setUpdatedDao] = useState({ logo: "", ...dao, minimumDuration: minimumDuration });
    const [image, setImage] = useState(false);
    const handleImageChange = (k, e, y = "") => {
        if (k === "icons") {
            handleImageDrop(handleChange, "logo", e, y);
            return;
        } else {
            setUpdatedDao({
                ...updatedDao,
                [k]: e.target.value,
            });
        }
    };
    const revertChanges = () => {
        const minimumDuration = extractMinimumDurationFromTimeStamp(dao.minimumDuration);
        setUpdatedDao({ logo: "", ...dao, minimumDuration: minimumDuration });
    };
    const handleChange = (k, e) => {
        if (k === "earlyExecution" && updatedDao.voteChange === true) {
            setUpdatedDao({
                ...updatedDao,
                [k]: e,
                voteChange: false,
            });
            return;
        }
        setUpdatedDao({
            ...updatedDao,
            [k]: e,
        });
    };
    useEffect(() => {
        if (image) {
            document.getElementById("createTokenImageUploadInput").click();
            setImage(false);
        }
    }, [image]);
    const dispatch = useDispatch();
    useEffect(() => {
        const minimumDuration = String(convertJsonToTimeStamp(updatedDao.minimumDuration));
        dispatch(proposalsActionsConfigureVal("settings", { ...updatedDao, minimumDuration: minimumDuration }));
    }, [updatedDao]);
    return (
        <div className={css.container}>
            <div className={css.flexRowEnd}>
                <Button className={css4.button} type="default" onClick={revertChanges}>
                    Revert Changes
                </Button>
            </div>
            <div className={css.card}>
                <span className={css.title}>DAO</span>
                <div className={css.line}></div>
                <div className={css2.card}>
                    <div className={css2.cardTop}>
                        <span className={css2.label}>Name</span>
                    </div>
                    <div className={css2.nameContainer}>
                        <input
                            value={updatedDao.daoName}
                            onChange={(e) => {
                                handleChange("daoName", e.target.value);
                            }}
                            type="text"
                            maxLength={128}
                            className={css2.input}
                        />
                        <span className={css2.length}>{updatedDao?.daoName?.length}/128</span>
                    </div>
                    <span className={css2.note}>Maximum of 128 characters</span>
                </div>
                <div className={css2.card}>
                    <div className={css2.cardTop}>
                        <span className={css2.label}>Logo</span>
                        <span className={css2.optional}>{"(optional)"}</span>
                    </div>
                    <div className={`${css2.logoContainer} ${updatedDao.logo === "" ? css2.emptyLogoContainer : css2.filledLogoContainer}`}>
                        {updatedDao?.logo?.length === 0 && (
                            <div
                                className={css2.empty}
                                onDrop={(e) => {
                                    handleImageChange("icons", e, "drop");
                                }}
                                onDragOver={(e) => {
                                    e.preventDefault();
                                }}
                            >
                                <div className={css2.iconContainer}>
                                    <img className={css2.icon} src={uploadImg} alt="Upload" />
                                </div>
                                <div className={css2.textContainer}>
                                    <span
                                        className={css2.clickable}
                                        onClick={() => {
                                            setImage(true);
                                        }}
                                    >
                                        Click to upload
                                    </span>
                                    <span className={css2.test}>or drag and drop</span>
                                </div>
                                <input
                                    id="createTokenImageUploadInput"
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(e) => {
                                        handleImageChange("icons", e, "input");
                                    }}
                                />
                            </div>
                        )}
                        {updatedDao?.logo?.length > 0 && (
                            <div className={css2.filled}>
                                <img className={css2.logo} src={updatedDao?.logo} alt={updatedDao.logo} />
                                <div className={css2.deleteLogo}>
                                    <DeleteFilled
                                        onClick={() => {
                                            handleImageChange("logo", { target: { value: "" } });
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                    <span className={css2.note}>JPG, PNG or GIF of no more than 3MB. We recommend 1024x1024px.</span>
                </div>
                <div className={css2.card}>
                    <div className={css2.cardTop}>
                        <span className={css2.label}>Description</span>
                        <div className={css2.right}>
                            <div className={css2.generateUsingAI}>
                                <img className={css2.icon} src={generateAIImg} alt="Generate Using AI" />
                                <span>Generate using AI</span>
                            </div>
                        </div>
                    </div>
                    <div className={css2.description}>
                        <TextArea
                            className={css2.textArea}
                            value={updatedDao.description}
                            onChange={(e) => {
                                handleChange("description", e.target.value);
                            }}
                            autoSize
                        />
                    </div>
                    <span className={css2.note}>
                        Describe your DAO's purpose in a few sentences. This is listed on the Explore page so new contributors can find you.
                    </span>
                </div>
            </div>
            <div className={css.card}>
                <span className={css.title}>Governance</span>
                <div className={css.line}></div>

                {dao.governanceParticipation === "tokenHolders" && <div className={css3.flexColumn}>
                    <span className={css3.label}>Support Threshold</span>
                    <div className={css3.flexColumn2}>
                        <div className={css3.gridRowSupport}>
                            <div className={css3.increaseDecreaseInput}>
                                <img
                                    className={css3.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (updatedDao.supportThreeshold > 1) {
                                            handleChange("supportThreeshold", updatedDao.supportThreeshold - 1);
                                        }
                                    }}
                                />
                                <input className={css3.input} type="text" readOnly value={`<${updatedDao.supportThreeshold}%`} />
                                <img
                                    className={css3.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (updatedDao.supportThreeshold < 100) {
                                            handleChange("supportThreeshold", updatedDao.supportThreeshold + 1);
                                        }
                                    }}
                                />
                            </div>
                            <div className={css3.supportThreeshold}>
                                <Button
                                    className={css3.supportThreesholdButtons}
                                    onClick={() => {
                                        if (updatedDao.supportThreeshold < 100) {
                                            handleChange("supportThreeshold", updatedDao.supportThreeshold + 1);
                                        }
                                    }}
                                >
                                    Yes
                                </Button>
                                <Progress className={css3.supportThreesholdProgress} percent={53} showInfo={false} />
                                <Button
                                    className={css3.supportThreesholdButtons}
                                    onClick={() => {
                                        if (updatedDao.supportThreeshold > 1) {
                                            handleChange("supportThreeshold", updatedDao.supportThreeshold - 1);
                                        }
                                    }}
                                >
                                    No
                                </Button>
                            </div>
                        </div>
                        <span className={css3.description}>
                            The percentage of tokens that vote "Yes" in support of a proposal, out of all tokens that have voted, must be greater than
                            this value for the proposal to pass.
                        </span>
                        {updatedDao.supportThreeshold > 50 && <span className={css3.greenNote}>Proposal will be approved by majority</span>}
                    </div>
                </div>}

                {dao.governanceParticipation === "tokenHolders" && <div className={css3.flexColumn}>
                    <span className={css3.label}>Minimum Participation</span>
                    <div className={css3.flexColumn2}>
                        <div className={css3.gridRowSupport}>
                            <div className={css3.increaseDecreaseInput}>
                                <img
                                    className={css3.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (updatedDao.minimumParticipation > 1) {
                                            handleChange("minimumParticipation", updatedDao.minimumParticipation - 1);
                                        }
                                    }}
                                />
                                <input className={css3.input} type="text" readOnly value={`<${updatedDao.minimumParticipation}%`} />
                                <img
                                    className={css3.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (updatedDao.minimumParticipation < 100) {
                                            handleChange("minimumParticipation", updatedDao.minimumParticipation + 1);
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <span className={css3.description}>
                            The percentage of tokens that participate in a proposal, out of the total coin supply, must be greater than or equal to
                            this value for the proposal to pass.
                        </span>
                    </div>
                </div>}
                <div className={css3.flexColumn}>
                    <span className={css3.label}>Minimum Duration</span>
                    <div className={css3.flexColumn2}>
                        <span className={css3.description}>
                            The shortest period of time a proposal is open for voting. Proposals can be created with a longer duration, but not
                            shorter.
                        </span>
                        <div className={css3.gridRow3}>
                            <span className={css3.label12}>Minutes</span>
                            <span className={css3.label12}>Hours</span>
                            <span className={css3.label12}>Days</span>
                        </div>
                        <div className={css3.gridRow3}>
                            <div className={css3.increaseDecreaseInput}>
                                <img
                                    className={css3.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.minutes > 0) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                minutes: updatedDao.minimumDuration.minutes - 1,
                                            });
                                        }
                                    }}
                                />
                                <input
                                    className={css3.input}
                                    type="text"
                                    value={updatedDao.minimumDuration.minutes}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 60) {
                                            handleChange("minimumDuration", { ...updatedDao.minimumDuration, minutes: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css3.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.minutes < 59) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                minutes: updatedDao.minimumDuration.minutes + 1,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className={css3.increaseDecreaseInput}>
                                <img
                                    className={css3.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.hours > 0) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                hours: updatedDao.minimumDuration.hours - 1,
                                            });
                                        }
                                    }}
                                />
                                <input
                                    className={css3.input}
                                    type="text"
                                    value={updatedDao.minimumDuration.hours}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 24) {
                                            handleChange("minimumDuration", { ...updatedDao.minimumDuration, hours: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css3.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.hours < 23) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                hours: updatedDao.minimumDuration.hours + 1,
                                            });
                                        }
                                    }}
                                />
                            </div>
                            <div className={css3.increaseDecreaseInput}>
                                <img
                                    className={css3.icon}
                                    src={minusImg}
                                    alt="Decrease"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.days > 1) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                days: updatedDao.minimumDuration.days - 1,
                                            });
                                        }
                                    }}
                                />
                                <input
                                    className={css3.input}
                                    type="text"
                                    value={updatedDao.minimumDuration.days}
                                    max={59}
                                    onChange={(e) => {
                                        if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) < 60) {
                                            handleChange("minimumDuration", { ...updatedDao.minimumDuration, days: parseInt(e.target.value) });
                                        }
                                    }}
                                />
                                <img
                                    className={css3.icon}
                                    src={plusImg}
                                    alt="Increase"
                                    onClick={() => {
                                        if (updatedDao.minimumDuration.days < 30) {
                                            handleChange("minimumDuration", {
                                                ...updatedDao.minimumDuration,
                                                days: updatedDao.minimumDuration.days + 1,
                                            });
                                        }
                                    }}
                                />
                            </div>
                        </div>
                        <span className={css3.description}>
                            Set this to a duration that is long enough for your members to have sufficient time to vote. It’s recommended to set this
                            to at least 1 day.
                        </span>
                    </div>
                </div>
                <div className={css3.flexColumn2}>
                    <span className={css3.label}>Early execution</span>
                    <span className={css3.description}>
                        Allows a proposal to be executed before the voting period ends if the support threshold is surpassed, the minimum
                        participation is met, and the outcome cannot be changed by more voters participating.
                    </span>
                    <div className={css3.gridRow2}>
                        <div
                            className={`${css3.checkboxContainer} ${updatedDao.earlyExecution === false ? css3.activeCheckbox : css3.inactiveCheckbox}`}
                            onClick={() => {
                                if (updatedDao.earlyExecution !== false) {
                                    handleChange("earlyExecution", false);
                                }
                            }}
                        >
                            <span>No</span>
                            <Checkbox className={css3.checkbox} checked={updatedDao.earlyExecution === false}></Checkbox>
                        </div>
                        <div
                            className={`${css3.checkboxContainer} ${updatedDao.earlyExecution === true ? css3.activeCheckbox : css3.inactiveCheckbox}`}
                            onClick={() => {
                                if (updatedDao.earlyExecution !== true) {
                                    handleChange("earlyExecution", true);
                                }
                            }}
                        >
                            <span>Yes</span>
                            <Checkbox className={css3.checkbox} checked={updatedDao.earlyExecution === true}></Checkbox>
                        </div>
                    </div>
                </div>
                <div className={css3.flexColumn2}>
                    <span className={css3.label}>Vote change</span>
                    <span className={css3.description}>
                        Allows voters to change their vote during the voting period. This setting can’t be enabled if early execution is enabled.
                    </span>
                    <div className={css3.gridRow2}>
                        <div
                            className={`${css3.checkboxContainer} ${updatedDao.voteChange === false ? css3.activeCheckbox : css3.inactiveCheckbox}`}
                            onClick={() => {
                                if (updatedDao.voteChange !== false) {
                                    handleChange("voteChange", false);
                                }
                            }}
                        >
                            <span>No</span>
                            <Checkbox className={css3.checkbox} checked={updatedDao.voteChange === false}></Checkbox>
                        </div>
                        <div
                            className={`${css3.checkboxContainer} ${updatedDao.voteChange === true ? css3.activeCheckbox : css3.inactiveCheckbox}`}
                            onClick={() => {
                                if (updatedDao.voteChange !== true && updatedDao.earlyExecution === false) {
                                    handleChange("voteChange", true);
                                }
                            }}
                        >
                            <span>Yes</span>
                            <Checkbox className={css3.checkbox} checked={updatedDao.voteChange === true}></Checkbox>
                        </div>
                    </div>
                </div>
            </div>
            <div className={css.card}>
                <span className={css.title}>Members</span>
                <div className={css.line}></div>
                <div className={css3.flexColumn2}>
                    <span className={css3.label}>Proposal Creation</span>
                    <span className={css3.description}>Specify who is permitted to create proposals and what the minimum requirement is.</span>
                    <div className={css3.flexColumn}>
                        <div
                            className={`${css3.tokenHoldersCard} ${updatedDao.proposalCreation === "members" ? css3.activeTokenHoldersCard : css3.inactiveTokenHoldersCard}`}
                            onClick={() => {
                                if (updatedDao.proposalCreation !== "members") {
                                    handleChange("proposalCreation", "members");
                                }
                            }}
                        >
                            <div className={css3.flexColumn2}>
                                <span className={css3.tokenHolderName}>Members</span>
                                <span className={css3.tokenHolderDescription}>
                                    Only token holders with at least the minimum required balance or delegates minimum required voting power can
                                    create proposals.
                                </span>
                            </div>
                            <Checkbox className={css3.checkbox} checked={updatedDao?.proposalCreation === "members"}></Checkbox>
                        </div>
                        <div
                            className={`${css3.tokenHoldersCard} ${updatedDao.proposalCreation === "anyWallet" ? css3.activeTokenHoldersCard : css3.inactiveTokenHoldersCard}`}
                            onClick={() => {
                                if (updatedDao.proposalCreation !== "anyWallet") {
                                    handleChange("proposalCreation", "anyWallet");
                                }
                            }}
                        >
                            <div className={css3.flexColumn2}>
                                <span className={css3.tokenHolderName}>Any Wallet</span>
                                <span className={css3.tokenHolderDescription}>Any connected wallet can create proposals.</span>
                            </div>
                            <Checkbox className={css3.checkbox} checked={updatedDao?.proposalCreation === "anyWallet"}></Checkbox>
                        </div>
                    </div>
                </div>
                {dao.governanceParticipation === "tokenHolders" && <div className={css3.flexColumn2}>
                    <span className={css3.label}>Minimum Requirement</span>
                    <div className={css3.gridRowSupport}>
                        <div className={css3.increaseDecreaseInput}>
                            <img
                                className={css3.icon}
                                src={minusImg}
                                alt="Decrease"
                                onClick={() => {
                                    if (updatedDao.minimumRequirement > 1) {
                                        handleChange("minimumRequirement", updatedDao.minimumRequirement - 1);
                                    }
                                }}
                            />
                            <input className={css3.input} type="text" value={`<${updatedDao.minimumRequirement}%`} readOnly />
                            <img
                                className={css3.icon}
                                src={plusImg}
                                alt="Increase"
                                onClick={() => {
                                    if (updatedDao.minimumRequirement < 100) {
                                        handleChange("minimumRequirement", updatedDao.minimumRequirement + 1);
                                    }
                                }}
                            />
                        </div>
                    </div>
                </div>}
                {/* {dao.governanceParticipation === "multisigMembers" && (
                    <div className={css3.flexColumn}>
                        <span className={css3.label}>Minimum Approval</span>
                        <div className={css3.flexColumn2}>
                            <div className={css3.gridRowSupport}>
                                <div className={css3.increaseDecreaseInput}>
                                    <img
                                        className={css3.icon}
                                        src={minusImg}
                                        alt="Decrease"
                                        onClick={() => {
                                            if (updatedDao.minimumApproval > 1) {
                                                handleChange("minimumApproval", updatedDao.minimumApproval - 1);
                                            }
                                        }}
                                    />
                                    <input
                                        className={css3.input}
                                        type="text"
                                        value={updatedDao.minimumApproval}
                                        onChange={(e) => {
                                            if (/^[0-9]+$/.test(e.target.value) && parseInt(e.target.value) <= members.length) {
                                                handleChange("minimumApproval", parseInt(e.target.value));
                                            }
                                        }}
                                    />
                                    <img
                                        className={css3.icon}
                                        src={plusImg}
                                        alt="Increase"
                                        onClick={() => {
                                            if (updatedDao.minimumApproval < members.length) {
                                                handleChange("minimumApproval", updatedDao.minimumApproval + 1);
                                            }
                                        }}
                                    />
                                </div>
                                <div className={css3.minimumApproval}>
                                    <Progress className={css3.minimumApprovalProgress} percent={updatedDao.minimumApproval*100/members.length} showInfo={false} />
                                    <Tag className={css3.minimumApprovalAddress}>
                                        {updatedDao.minimumApproval} of {members.length} addresses
                                    </Tag>
                                </div>
                            </div>
                        </div>
                    </div>
                )} */}
            </div>
        </div>
    );
}

export default DAOEditSettings;
