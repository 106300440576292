import { useState } from "react";
import { switchCaseConstants, TEDAAS_SCHEMAS, TOKEN_SIMULATION } from "../Constants/Constants";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { getFilteredBQData, getFilteredEntities, insertEntity } from "../../../services/stf";
import { convertJsonToTimeStamp } from "../Constants/reusableFunctions";
import { showErrorToast, showWarningToast } from "../../../services/notificationService";

const usePostProposalData = () => {
    const dispatch = useDispatch();
    const signer = useSelector((state) => state.auth.signer);
    const signerAddress = useSelector((state) => state.auth.userAddress);
    const proposalData = useSelector((state) => state.pc);
    const proposalDetails = useSelector((state) => state.pd);

    const postProposalData = async (proposalAddress, tokenAddress) => {
        try {
            const mintActions = [];
            for (let i of proposalData.actions.mint) {
                mintActions.push({
                    memberAddress: i.address,
                    tokens: String(i.tokens),
                    tokenAddress: tokenAddress,
                });
            }
            const data = {
                proposalAddress: proposalAddress,
                daoAddress: proposalData.daoAddress,
                proposerAddress: signerAddress,
                title: proposalData.proposalMetaData.proposalTitle,
                description: proposalData.proposalMetaData.proposalDescription,
                body: { body: proposalData.proposalMetaData.proposalBody },
                resources: proposalData.proposalMetaData.proposalResources,
                votingType: proposalData.voting.votingOption,
                votingStartTime: String(proposalData.voting.startTime),
                votingEndTime: String(proposalData.voting.endTime),
                actions: {
                    withdraw: proposalData.actions.withdraw,
                    mint: mintActions,
                    settings: proposalData.actions.settings,
                    addWallets: proposalData.actions.addWallets,
                    removeWallets: proposalData.actions.removeWallets,
                },
                status: "pending",
                history: {
                    Published: String(new Date().getTime()),
                },
            };
            console.log(data);
            const res = await insertEntity([data], TEDAAS_SCHEMAS.proposalId, TEDAAS_SCHEMAS.accessToken);
            // const res2 = await insertEntity(tokenHolders, TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken);
            // const res3 = await insertEntity(daoMembers, TEDAAS_SCHEMAS.daoMemFollId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };
    const postProposalExecuteData = async (proposalAddress, tokenAddress, succeeded = false, executed = false) => {
        try {
            const history = {
                ...proposalDetails.history,
            };
            if (succeeded) {
                history.Succeeded = String(new Date().getTime());
            }
            if (executed) {
                history.Executed = String(new Date().getTime());
            }
            const data = {
                ...proposalDetails,
                status: executed ? "Executed" : succeeded ? "Succeeded" : "Expired",
                history: history,
            };
            const res = await insertEntity([data], TEDAAS_SCHEMAS.proposalId, TEDAAS_SCHEMAS.accessToken);
            // const res2 = await insertEntity(tokenHolders, TEDAAS_SCHEMAS.tokenHoldersId, TEDAAS_SCHEMAS.accessToken);
            // const res3 = await insertEntity(daoMembers, TEDAAS_SCHEMAS.daoMemFollId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            return false;
        }
    };

    const postVotingData = async (proposalAddress, userAddress, vote) => {
        try {
            const data = {
                proposalAddress: proposalAddress,
                userAddress: userAddress,
                vote: vote,
            };
            const res = await insertEntity([data], TEDAAS_SCHEMAS.voteId, TEDAAS_SCHEMAS.accessToken);
            return true;
        } catch (e) {
            console.log(e);
            showErrorToast("Voting data not saved");
            return false;
        }
    };

    return { postProposalData, postVotingData, postProposalExecuteData };
};

export default usePostProposalData;
